import { CreateReducer } from "../../shared/utils/createReducer";
import { ActionProps } from "../../shared/types/action.type";
import { TestAttempt } from "../../models/Test/test.model";
import { INVALID, VALID } from "../definitions/attemptConstants";

export interface AttemptState {
    valid: boolean;
    attempt?: TestAttempt;
}

export interface attemptReducerProps extends AttemptState {
    setAuthenticated: (attempt: TestAttempt) => ActionProps;
    setUnAuthenticated: () => ActionProps;
}

const initState: AttemptState = {
    valid: false,
    attempt: undefined
};

const attemptReducer = CreateReducer(initState, {
    [VALID](state: AttemptState, action: ActionProps): AttemptState {
        const { valid, attempt } = action?.payload;
        return {
            ...state,
            valid,
            attempt
        };
    },
    [INVALID](state: AttemptState, action: ActionProps): AttemptState {
        const { valid } = action?.payload;
        return { ...state, valid };
    }
});

export default attemptReducer;



