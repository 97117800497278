import { Button, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import * as appRoutes from '../../../../routes/routeConstants/appRoutes'
import {
    LeftOutlined
} from '@ant-design/icons'
import '../takeatest.scss'
import TestService from '../../../../services/TestService/test.service'
import ActionModal from '../../ActionModal'
import startTestImage from './../../../../assets/images/start-test.png'
import LocalStorage from '../../../localStorage'
import Notification from '../../Notification'
import { NotificationTypes } from '../../../../enums/notificationTypes'

const TakeATestCertificate = (props: any) => {
    const { loading, error, getSubTestDetails, test, createSubTestAttempt,createAttempt, attempt, startTest, getTestDetails } = TestService()
    const history = useHistory()
    const testId = window.location.pathname.split('/')[3]
    const [totalTime,setTotalTime] = useState("00 hours 00 minutes 00 seconds")

    const [startTestModalVisible, setStartTestModalVisible] = useState(false)

    useEffect(() => {
        // getSubTestDetails(window.location.pathname.split('/')[3],props.location.state || testId)
        getTestDetails(testId||props.location.state)
    }, [])
    
    useEffect(() => {
        error&&
            history.goBack()
    }, [error])

    const getHoursMinsSeconds = (time?:any,questions?:any) =>{
        var seconds=time*questions;
        var d = Number(seconds);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var _totalTime = `${h} hours ${m} minutes ${s} seconds`
        setTotalTime(_totalTime)
    }
    
    useEffect(()=>{
        getHoursMinsSeconds(test?.timePerQuestion,test?.numberOfQuestions)
        test &&
            LocalStorage.removeItem('tiger-test-id')
    },[test])

    useEffect(() => {
        (test?.attemptsRemaining === null || test?.attemptsRemaining === 0) &&
            Notification({
                message: "",
                description: "There are no attempts remaining to take the test!",
                type: NotificationTypes.INFO,
            });

        (test?.numberOfQuestions===null|| test?.numberOfQuestions == 0) &&
        Notification({
            message: "",
            description: "There are no questions available in the test!",
            type: NotificationTypes.INFO,
        });

        test?.status === "expired" ?
            Notification({
                message: "",
                description: "The test has expired!",
                type: NotificationTypes.INFO,
            }) :
            test?.status === "not_deployed" &&
            Notification({
                message: "",
                description: "The test is yet to be deployed!",
                type: NotificationTypes.INFO,
            })
    }, [test])

    return (
        <div className="app-wrapper test__instructions">
            <Row className="test__view-banner test__instructions-header">
                <div className="title__container">
                    <div className="title">
                        <LeftOutlined onClick={history.goBack} />
                        <h1 onClick={history.goBack}>
                            {test?.name}
                        </h1>
                    </div>
                    <div className="info">
                        <p>{test?.numberOfQuestions}  {test?.numberOfQuestions===1?"Question":"Questions"}</p>
                    </div>
                </div>
                <div className="footer">
                    {/* {test?.totalTime} */}
                    {totalTime}
                </div>
            </Row>
            <div className="test__instructions-texts">
                <div className="test__instructions-texts-list">
                    <h3>Instructions</h3>
                    <ul>
                        <li>
                            This is a timed test.
                        </li>
                        <li>
                            Please make sure you are not interrupted during the test, as the timer cannot be paused once started.
                        </li>
                        <li>
                            Please ensure you have a stable internet connection. Each question has a timer.
                        </li>
                        <li>
                            The test will automatically move onto the next question if you do not answer in the allotted time.
                        </li>
                    </ul>
                </div>
                <div className="test__instructions-texts-actions">
                    <Button type="text" onClick={history.goBack}>Cancel</Button>
                    <Button 
                    disabled={(error||test?.numberOfQuestions == null || test?.numberOfQuestions == 0 || test?.attemptsRemaining == 0 || `${test?.attemptsRemaining}` < "0" || `${test?.attemptsRemaining}` == "null" || test?.status === "expired" || test?.status === "not_deployed") ? true : false}
                    type="primary" onClick={() => {
                        setStartTestModalVisible(true)
                        // CHANGED
                        // createSubTestAttempt(window.location.pathname.split('/')[3],props.location.state||testId)
                        createAttempt(`${test?.id}`)
                        LocalStorage.removeItem('tiger-test-id')

                        // history.push(`${match.url}/start-test`, props.location.state)
                        // history.push(appRoutes.TAKE_A_TEST_VIEW.replace(':testId',props.location.state),test)
                    }}>Continue</Button>
                </div>
            </div>
            <ActionModal
                modalClassName={"start-test-modal"}
                modalVisible={startTestModalVisible}
                closeModal={() => setStartTestModalVisible(false)}
                component={
                    <div className="modal-content">
                        <img src={startTestImage} alt="" />
                        <h2>{test?.name}</h2>
                        <div className="actions">
                            <Button type="text" onClick={() => setStartTestModalVisible(false)}>Cancel</Button>
                            <Button className="blue" type="primary" onClick={() => {
                                attempt && startTest(`${attempt?.id}`).then(() => {
                                    history.push(appRoutes.TAKE_A_TEST_VIEW.replace(':testId', testId||props.location.state), test)
                                })
                            }}>Start Test</Button>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default TakeATestCertificate
