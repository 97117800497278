import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import { Tests, Topics } from "../../models/Meta/meta.model";
import { Countries, HelpTabs } from "../../models/Profile/profile.model";

const MetaService = () => {
    const history = useHistory();

    const [topicsMeta, setTopicsMeta] = useState<Topics>();
    const [testsMeta, setTestsMeta] = useState<Tests>();
    const [countries, setCountries] = useState<Countries>()
    const [helpTabs, setHelpTabs] = useState<HelpTabs>()


    const [error, setError] = useState<Error>();

    const [loading, setLoading] = useState(false);

    const getTopicsMeta = (search?:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.META_TOPICS,{params:{"search":search}})
            .then((response) => {
                const _topics = deserialize(Topics, response.data);
                setTopicsMeta(_topics);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestsMeta = (topicId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.META_TESTS.replace(':topicId',topicId))
            .then((response) => {
                const _tests = deserialize(Tests, response.data);
                setTestsMeta(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCountriesMeta = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.META_COUNTRIES)
            .then((response) => {
                const _countries = deserialize(Countries, response.data);
                setCountries(_countries);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getHelpTabs = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.META_HELP_TABS)
            .then((response) => {
                const _helptabs = deserialize(HelpTabs, response.data);
                setHelpTabs(_helptabs);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        error,
        loading,
        topicsMeta,
        getTopicsMeta,
        getTestsMeta,
        testsMeta,
        getCountriesMeta,
        countries,
        getHelpTabs,
        helpTabs
    };
};

export default MetaService;
