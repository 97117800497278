import React, { useState } from 'react'
import { useHistory } from 'react-router'
import NewQuestionForm from '../NewQuestionForm'
import './newquestionview.scss'

const NewQuestionView = (props: any) => {
    const history = useHistory()
    const [newQuestionModalVisible, setNewQuestionModalVisible] = useState(true);

    return (
        <div className="app-wrapper">
            <h1 className="newquestion-title">New Question</h1>
            <div className="newquestion__container">
                <NewQuestionForm
                isQuestionView={true}
                    addNoMore={false}
                    testId={props.location.state?.testId}
                    topicId={props.location.state?.topicId}
                    onClose={() => {
                        setNewQuestionModalVisible(false)
                    }} />
            </div>
        </div>
    )
}

export default NewQuestionView
