import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Row, Tabs, Table, Checkbox, Radio, Drawer, Input } from 'antd'
import testImage from './../../assets/images/topic.png'
import './earncertificate.scss'
import ActionModal from '../../shared/components/ActionModal';
import { useHistory, useRouteMatch } from 'react-router';
import * as appRoutes from './../../routes/routeConstants/appRoutes'
import FilterDrawer from '../../shared/components/FilterDrawer';
import { Form, Formik, FormikValues } from 'formik';
import TopicService from '../../services/TopicService/topic.service';
import TestService from '../../services/TestService/test.service';
import MetaService from '../../services/MetaService/meta.service';
import {
    SearchOutlined
} from '@ant-design/icons'
import { debounce } from "lodash";
import { Test, TopicTest } from '../../models/Test/test.model';

const { TabPane } = Tabs;

const EarnCerificate = () => {
    const { loading, error, getCertificates, certificates, getTopics, topics, getAttemptedCertificateTests, attemptedCertificates } = TopicService()
    const { getTopicsMeta, topicsMeta } = MetaService()
    const { getTopicTests, topicTests } = TestService()
    const match = useRouteMatch()
    const columns = [
        {
            title: '',
            dataIndex: 'name',
        },
    ];
    const [tabKey, setTabKey] = useState("1")

    const [selectTestModalVisible, setSelectTestModalVisible] = useState(false);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState("")

    const closeSelectTestModal = () => {
        setSelectTestModalVisible(false)
    }
    const openSelectTestModal = () => {
        setSelectTestModalVisible(true)
    }
    const history = useHistory()

    const getSearchTopics = (query: string) => {
        // PASS SEARCH TEAT TO META
        getTopicsMeta(query)
    }

    const handleSearch = useCallback(debounce(q => getSearchTopics(q), 1000, {}), [])

    const onChange = (e: any) => handleSearch(e?.target?.value)

    useEffect(() => {
        getCertificates()
        getAttemptedCertificateTests()
        getTopics()
        getTopicsMeta()
    }, [])

    useEffect(() => {
        selectedTopic !== "" && getTopicTests(selectedTopic)
    }, [selectedTopic])

    const onSubmit = (values: FormikValues) => {
        setSelectedTopic(values?.topicId)
        setFilterModalVisible(false)
        openSelectTestModal()
    }

    return (
        <div className="app-wrapper earn__certificate">
            <div className="test__view-menubar">
                {
                    tabKey === "1" &&
                    <Button type="primary" className="add-question" onClick={() => setFilterModalVisible(true)}> <i className="icon-filter"></i> Filter</Button>
                }
                <Tabs defaultActiveKey="1" onChange={() => { }} onTabClick={(key, event) => {
                    setTabKey(key)
                    // setRender(state => !state)
                }}>
                    <TabPane tab="Earn a Certificate" key="1">
                        <Row gutter={20} className="earn__certificate-content">
                            <Col span={16}>
                                <Row gutter={[15, 15]} className="list__tests">
                                    {
                                        topics?.topics?.map((test) => (
                                            <Col lg={12} sm={24} >
                                                <div className="test_card" onClick={test?.noOfTests ? openSelectTestModal : () => { }} onMouseEnter={() => setSelectedTopic(`${test?.id}`)}>
                                                    <div className="image-wrapper">
                                                        <img src={test?.imageUrl || testImage} alt="" />
                                                    </div>
                                                    <div className="test-info">
                                                        <h3>{test?.name}</h3>
                                                        <p className="tests-badge">{test?.noOfTests} Tests</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Col>
                            <Col span={8} className="topics-list__search-bar">
                                <div className="search-box">
                                    <h2>Try Again</h2>
                                    <div className="list-languages">
                                        <Table loading={loading} onRow={(record, index) => ({
                                            // onClick: (event) => { }
                                            onClick:() => {
                                                 history.push(appRoutes.TAKE_A_TOPIC_TEST.replace(':testId', `${record?.encryptedTestId}`), record?.encryptedTestId)
                                            }
                                        })}
                                            columns={columns}
                                            dataSource={attemptedCertificates?.tests}
                                            size="middle"
                                            pagination={false}
                                            scroll={{ y: 580 }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Your Certificates" key="2">
                        <div className="certificate-tests">
                            <Row gutter={[10, 10]}>
                                {
                                    certificates?.certificates?.map((test) => (
                                        <Col xl={8} sm={12}>
                                            <div className="certificate-tests-card" onClick={() => history.push(`${match.url}/${test?.id}/certificate`)}>
                                                <h2>{test?.name}
                                                    <i className="icon-certificate"></i>
                                                </h2>
                                                <div className="footer">
                                                    <p> <i className="icon-correct-answer"></i> Completed</p>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>

                    </TabPane>
                </Tabs>
            </div>
            <ActionModal
                title="Select Test"
                modalClassName="select_test-modal"
                width={732}
                modalVisible={selectTestModalVisible}
                closeModal={closeSelectTestModal}
                component={
                    <>
                        <div className="all__tests">
                            {
                                topicTests?.tests?.length ?
                                    topicTests?.tests?.map((test:TopicTest) => (
                                        <div className="all__tests-card" onClick={() => test?.attemptsRemaining && test.attemptsRemaining > 0  && history.push(appRoutes.TAKE_A_TOPIC_TEST.replace(':testId', `${test?.encryptedTestId}`), test?.encryptedTestId)}>
                                            <h3>{test?.name}
                                                <i className="icon-certificate"></i>
                                            </h3>
                                            <p className={`status ${test?.difficultyLevel}`}>{test?.difficultyLevel}</p>
                                            {/* <p className={`status ${test?.difficulty_level}`}>{test?.difficulty_level}</p> */}
                                        </div>
                                    )) :
                                    "No tests added!"}
                        </div>
                        <div className="all__tests-controls">
                            <Button type="text" onClick={() => setSelectTestModalVisible(false)}>Cancel</Button>
                            <Button type="primary" onClick={() => {
                                // setSuccessModalVisible(true)
                            }}>Take test</Button>
                        </div>
                    </>
                }
            />
            <Drawer
                title={
                    <div className="deploy-hiringtest-title topics-filter-title">
                        <h1>Filter</h1>
                        <Input placeholder="Search" name="search" type="text" addonAfter={<SearchOutlined />} onChange={onChange} />
                    </div>
                }
                placement="right"
                width={"34vw"}
                onClose={() => setFilterModalVisible(false)}
                visible={filterModalVisible}
                closable={true}
                maskStyle={{
                    opacity: .78,
                    backgroundColor: "#000"
                }}
                className="filter-drawer topics-filter"
            >
                <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="select-topic-form">
                            <div className="select-topic-content">
                                <Row gutter={[10, 10]}>
                                    <Col span={24}>
                                        <div className="topics">
                                            <Radio.Group name="topicId" options={topicsMeta?.topics?.map((value) => ({ label: value?.name ?? "", value: value?.id as number })) ?? []}
                                                onChange={(checkedValues) => {
                                                    setFieldValue("topicId", checkedValues.target.value)

                                                }} />
                                            {/* <Checkbox.Group options={listTypes.map((value) => ({ label: value?.type ?? "", value: value?.id })) ?? []} onChange={(checkedValues) => setFieldValue("types", checkedValues)} /> */}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="select-topic-actions">
                                <Button type="text" onClick={() => setFilterModalVisible(false)}>Cancel</Button>
                                <Button type="primary" htmlType="submit">Apply</Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </Drawer>

        </div>
    )
}

export default EarnCerificate
