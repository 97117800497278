import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from 'antd';
import Error from "../Error";
import TextArea from "antd/lib/input/TextArea";

interface InputFieldProps {
    className?: string;
    title?: string;
    type?: string;
    rows?: number;
    maxLength?: number;
    name: string;
    placeholder: string;
    addonAfter?: any;
    addonBefore?: any;
    disabled?: boolean;
    onBlur?: (params: Event) => void;
    onFocus?: (params: Event) => void;
    onInput?: (params: Event) => void;
}

const InputField: FC<InputFieldProps> = (props) => {
    const { name, title, type, className } = props;
    return (
        <div className={className}>
            <label htmlFor={name}>
                {title}
            </label>
            <Field
                as={type == "password" ? Input.Password : type==="textarea" ? TextArea : Input}
                {...props} />
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default InputField;