import React, { useEffect } from 'react'
import { Row, Switch, Table } from 'antd'
import './managequestions.scss'
import QuestionService from '../../../services/QuestionService/question.service'
import { QuestionTypes } from '../../../enums/questionTypes'
import ParagraphEllipsis from '../../../shared/components/ParagraphEllipsis'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { convertJSONToFormData } from '../../../shared/utils/formDataConvertor'

const ManageQuestions = (props: { type?: string, testId?: string, render?: boolean }) => {
    const { questions, getTestQuestions, editTestQuestion, loading, error, questionEdited } = QuestionService()
    const testId = window.location.pathname.split('/')[2]
    const history = useHistory()
    const match = useRouteMatch()

    const onChange = (e: any, id: string, status: string) => {
        const question = questions?.questions?.find(question=>question?.id === Number(id))
        const options = question?.options?.map((question)=>question?.id)
        const payload = convertJSONToFormData({"question": {status, options}})

        editTestQuestion(id, payload).then(() => {
            getTestQuestions({ test_id: testId, status: props?.type })
        })
    }

    useEffect(() => {
        if (props?.type) {
            getTestQuestions({ test_id: testId, status: props?.type })
        } else {
            getTestQuestions({ test_id: testId })
        }
    }, [props.render,questionEdited])

    const columns = [
        {
            title: 'QUESTION ID',
            dataIndex: 'id',
        },
        {
            title: 'QUESTION',
            dataIndex: 'title',
            render: ((_: any, row: any) => (
                <ParagraphEllipsis onClick={()=>history.push(`${match.url}/${row?.id}/view`,row?.id)} text={row?.title}></ParagraphEllipsis>
            )),
        },
        {
            title: 'DATE',
            dataIndex: 'createdAt',
        },
        {
            title: 'CREATED BY',
            dataIndex: 'createdBy',
            render: ((_: any, row: any) =>
            (
                <div className="manage-question">
                    <p className={`status ${row?.status}`}>{row?.createdBy}</p>
                    {
                        props.type &&
                        <div className="switch-wrapper">
                            <Switch checked={true} defaultChecked={true} onChange={(e) => onChange(e, row?.id, props.type === QuestionTypes.APPROVED ? QuestionTypes.REJECTED : props.type === QuestionTypes.PENDING_APPROVAL ? QuestionTypes.APPROVED : props.type === QuestionTypes.REJECTED ? QuestionTypes.APPROVED : "")} />
                        </div>
                    }
                </div>
            ))
        },
    ];

    return (
        <Row className="manage__question-table antd-table-full">
            <Table onRow={(record, index) => ({
                onClick: (event) => {
                    // history.push(`${match.url}/${record?.id}/view`,record?.id)
                }
            })}
                columns={columns}
                dataSource={questions?.questions}
                size="middle" pagination={false}
                scroll={{ y: 448 }}
                loading={loading} />
        </Row>
    )
}

export default ManageQuestions
