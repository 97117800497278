import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { DatePicker, Input, Select } from 'antd';
import Error from "../Error";

const { Option } = Select;

interface SelectFieldProps {
    className?: string;
    title?: string;
    type?: string;
    name: string;
    placeholder: string;
    addonAfter?: any;
    addonBefore?: any;
    disabled?: boolean;
    onBlur?: (params: Event) => void;
    onFocus?: (params: Event) => void;
    setFieldValue?: any;
    dropdownClassName: string;
    size: string;
    onClick?: () => void;
    onChange?: () => void;
    countries?: any;
    value?: any;
    defaultValue?: string;
}

const SelectField: FC<SelectFieldProps> = (props) => {
    const { name, title, setFieldValue, placeholder, dropdownClassName, onClick, countries, value, defaultValue } = props;
    return (
        <div>
            <label htmlFor={name}>
                {title}
            </label>
            <Select
                defaultValue={defaultValue}
                value={value}
                onChange={!countries ?
                    (date, dateString) => {
                        setFieldValue(name, date)
                    } :
                    (value) => { setFieldValue(name, value) }} placeholder={placeholder}
                dropdownClassName={dropdownClassName}
                onClick={onClick}>
                {countries?.countries?.map((option: any) => (
                    <Option value={option.id}>{option.name}</Option>
                ))}
            </Select>
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default SelectField;