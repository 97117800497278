import { combineReducers } from "redux";
import attemptReducer, { AttemptState } from "./attemptReducer";
import authReducer, { AuthState } from "./authReducer";

export interface RootReducerProps {
    auth: AuthState,
    attempt: AttemptState
}

const RootReducer = combineReducers<RootReducerProps>({
    auth: authReducer,
    attempt: attemptReducer
});

export default RootReducer;