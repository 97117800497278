import { ActionProps } from "../../shared/types/action.type";
import { TestAttempt } from "../../models/Test/test.model";
import { INVALID, VALID } from "../definitions/attemptConstants";

export const setValid = (attempt: TestAttempt): ActionProps => ({
    type: VALID,
    payload: {
        valid: true,
        attempt
    }
})

export const setInvalid = (): ActionProps => {
    return {
        type: INVALID,
        payload: {
            valid: false,
            attempt: null
        }
    }
}