import React, { useEffect, useState } from "react";
import "./topicslist.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { useHistory, withRouter, useRouteMatch } from "react-router";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { Button, Col, Modal, Popover, Row, Skeleton, Space } from "antd";
import { Typography } from 'antd';
import {
    EllipsisOutlined,
} from "@ant-design/icons";
import topicImage from '../../../assets/images/topic.png';
import NewQuestionDrawer from "../NewQuestionDrawer";
import ActionModal from "../ActionModal";
import TestService from "../../../services/TestService/test.service";
import LocalStorage from "../../localStorage";
import { TestTypes } from "../../../enums/testTypes";
import { Topics } from "../../../models/Topic/topic.model";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface TopicsListProps {
    topics: any,
    cols: number
}

const { Paragraph, Text } = Typography;

const TopicsList = (props: TopicsListProps) => {
    const { loading, error, getTopicTests, topicTests } = TestService()
    let match = useRouteMatch();
    const history = useHistory()
    const { topics, cols } = props
    const [selectedTopic, setSelectedTopic] = useState("")
    const [ellipsis, setEllipsis] = useState(true);
    const [newQuestionModalVisible, setNewQuestionModalVisible] = useState(false);
    const [selectTestModalVisible, setSelectTestModalVisible] = useState(false);

    const topicType = (window as any).topicType || "topics"

    const closeSelectTestModal = () => {
        setSelectTestModalVisible(false)
    }
    const popoverContent = (noOfTests: string, topicId: number) =>
        <Space direction="vertical">
            <ul className="popover-list">
                <li onClick={(e) => {
                    e.stopPropagation()
                    history.push(appRoutes.DEPLOY_CERTIFICATE_HIRINGTEST, { testType: TestTypes.CERTIFICATE, topicId: topicId })
                }}>Deploy test</li>
                <li onClick={(e) => {
                    e.stopPropagation()
                    setNewQuestionModalVisible(true)
                }}>Contribute Questions</li>
                <li onClick={(e) => {
                    e.stopPropagation()
                    noOfTests ?
                        setSelectTestModalVisible(true) :
                        Notification({
                            message: "Can't Proceed",
                            description: "There are no certificates in this topic!",
                            type: NotificationTypes.INFO,
                        });
                }}>Earn Certificate</li>
            </ul>
        </Space>

    const loadingTopics = (
        <Row gutter={[20, 20]} className="mt-5">
            {Array(window.innerWidth < 480 ? 4 : 8)
                .fill(1)
                .map(() => (
                    <Col span={window.innerWidth < 480 ? 12 : 6}>
                        <Skeleton.Image />
                        <Skeleton paragraph={{ rows: 2 }} active />
                    </Col>
                ))}
        </Row>
    );

    useEffect(() => {
        setTimeout(() => {
            (LocalStorage.getItem("selected-topic") || LocalStorage.getItem("selected-test")) &&
                setNewQuestionModalVisible(true)
        }, 500);
    }, [loading, newQuestionModalVisible])

    useEffect(() => {
        selectedTopic !== "" && getTopicTests(selectedTopic)
    }, [selectedTopic])

    return (
        <>
            <Row className="topic__items" gutter={[12, 12]}>
                {
                    topics?.map((topic: any) => (
                        <Col xl={cols} xs={12} onClick={() => {
                            cols == 8 ?
                                history.push(`${match.url}/${topic?.id}`) :
                                history.push(`${match.url}/proposed-topic/${topic?.id}`)
                        }}>
                            <div className={`topic-item ${cols == 8 ? "topics" : "proposed-topics"}`}>
                                {
                                    cols == 8 &&
                                    <Popover placement="bottomRight" content={popoverContent(topic?.noOfTests, topic?.id)} trigger="hover">
                                        <Button type="text" className="popover-btn" onMouseEnter={() => setSelectedTopic(topic?.id)} onClick={(e) => {
                                            e.stopPropagation()
                                            // setSelectedTopic(topic?.id)
                                        }}><EllipsisOutlined /></Button>
                                    </Popover>
                                }
                                <div className="header">
                                    <img decoding="async" src={topic.imageUrl || topicImage} alt="" />
                                </div>
                                <div className="info">
                                    {cols == 8 && <p className="tests-badge">{topic.noOfTests} {topic.noOfTests === 1 ? "Test" : "Tests"}</p>}
                                    <h2 className="title">{topic?.name}{topic?.description?.trim().length ? ": "+topic.description : ""}</h2>
                                    {cols == 8 && <p className="new-questions">{topic.noOfQuestions} {topic.noOfQuestions === 1 ? "question" : "questions"}</p>}
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
            <NewQuestionDrawer topicId={selectedTopic} visible={newQuestionModalVisible} onClose={() => setNewQuestionModalVisible(false)} />

            <ActionModal
                title="Select Test"
                modalClassName="select_test-modal"
                width={732}
                modalVisible={selectTestModalVisible}
                closeModal={closeSelectTestModal}
                component={
                    <>
                        <div className="all__tests">
                            {topicTests?.tests?.map((test) => (
                                <div className="all__tests-card" onClick={() => history.push(appRoutes.TAKE_A_TOPIC_TEST.replace(':testId', `${test?.encryptedTestId}`), test?.encryptedTestId)}>
                                    <h3>{test?.name}
                                        <i className="icon-certificate"></i>
                                    </h3>
                                    <p className={`status ${test?.difficultyLevel}`}>{test?.difficultyLevel}</p>
                                    {/* <p className={`status ${test?.difficulty_level}`}>{test?.difficulty_level}</p> */}
                                </div>
                            ))}
                        </div>
                        <div className="all__tests-controls">
                            <Button type="text" onClick={() => setSelectTestModalVisible(false)}>Cancel</Button>
                            <Button type="primary" onClick={() => {
                                // setSuccessModalVisible(true)
                            }}>Take test</Button>
                        </div>
                    </>
                }
            />
        </>
    );
}

export default TopicsList;