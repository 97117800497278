import { Button, Col, Row } from 'antd'
import { Field, FieldArray, Form, Formik, FormikProps, FormikValues } from 'formik'
import React, { Fragment, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import InputField from '../InputField'

import { Upload, Radio, Space } from 'antd';
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from '../../../enums/notificationTypes'
import './newquestionform.scss'
import SuccessModal from '../SuccessModal'
import { questionValidation } from './questionValidation'
import QuestionService from '../../../services/QuestionService/question.service'
import { convertJSONToFormData } from '../../utils/formDataConvertor'
import { Option as OptionModel } from '../../../models/Question/question.model'
import LocalStorage from '../../localStorage'
import { removeUndefined } from '../../utils/removeUndefinied'

const NewQuestionForm = (props: any) => {
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const { testId, topicId, onClose, addNoMore, maskClosable, isQuestionView } = props
    const history = useHistory()
    const { createTopicQuestion, createTestQuestion, createOption, updateOption, newOption, loading, error, questionError, createQuestionLoading } = QuestionService()
    const [selectedIndex, setSelectedIndex] = useState(0)

    const [successModalVisible, setSuccessModalVisible] = useState(false)
    const [loadingOption, setLoadingOption] = useState<number>()

    const initialValues = {
        difficulty_level: "medium",
        title: "",
        image: "",
        correct_answer_id: null,
        optionsArray: [
            {
                "id": "00",
                "text": null,
                "imageUrl": "null"
            },
            {
                "id": "01",
                "text": null,
                "imageUrl": "null"
            },
            {
                "id": "02",
                "text": null,
                "imageUrl": "null"
            },
            {
                "id": "03",
                "text": null,
                "imageUrl": "null"
            }
        ],
        options: []
    }

    const questionSuccessCallback = () => {
        setSuccessModalVisible(true)
        formRef?.current?.resetForm({ values: {} });
    }

    return (
        <Fragment>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={(values: FormikValues, { resetForm }) => {
                    const payload = removeUndefined({ ...values });
                    typeof payload?.image === "string" && delete payload.image;
                    payload.options = payload.optionsArray?.map((data: any, i: number) => {
                        if (typeof data?.id == "number") {
                            return data?.id
                        }
                    })
                    delete payload.option_1;
                    delete payload.option_2;
                    delete payload.option_3;
                    delete payload.option_4;
                    payload.tags = payload?.tags?.split(',')
                    payload.difficulty_level = payload?.difficulty_level.toLowerCase()

                    testId ?
                        createTestQuestion(payload, testId || LocalStorage.getItem("selected-test"), questionSuccessCallback, ()=>{}) :
                        createTopicQuestion(payload, topicId || LocalStorage.getItem("selected-topic"), questionSuccessCallback, ()=>{});
                }}
                validationSchema={questionValidation}
                enableReinitialize
            >
                {({ values, setFieldValue, isValid, dirty, errors }) => (
                    <Form className="create__form">
                        <div className="create__form-fields">
                            <div className="questions">
                                <div className="title">
                                    <InputField type="textarea" name="title" placeholder="Question" rows={1} maxLength={200} />
                                    <Field
                                        as={Upload}
                                        multiple={false}
                                        maxCount={1}
                                        onChange={(info: any) => {
                                            const { status } = info.file;
                                            if (status !== 'uploading' && status !== "removed") {
                                                setFieldValue("image", info?.file)
                                            }
                                            else {
                                                setFieldValue("image", null)
                                            }
                                        }}
                                        accept="image/*"
                                        name="image"
                                        beforeUpload={() => false}
                                    >
                                        <h3 className="ant-upload-text">
                                            <i className="icon-image"></i>
                                        </h3>
                                    </Field>
                                </div>
                                <div className="difficulty-level">
                                    <InputField name="tags" placeholder="Tags (Optional)" />
                                    <div>
                                        <p className='field-label'>Question difficulty level</p>
                                        <Radio.Group defaultValue={"Medium"} onChange={(e: any) => setFieldValue("difficulty_level", e.target.value)}>
                                            <Radio value={"Easy"}>Easy</Radio>
                                            <Radio value={"Medium"}>Medium</Radio>
                                            <Radio value={"Hard"}>Hard</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="answers">
                                <Row>
                                    <Col span={10}>
                                        <h2>Your Answers</h2>
                                    </Col>
                                    <Col span={3}>
                                        <p>Correct Answer</p>
                                    </Col>
                                    <Col span={2}>
                                        <p>Image</p>
                                    </Col>
                                    <Col span={2}>
                                        <p>Delete</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Radio.Group onChange={(e) => {
                                            e.target.value?.[0] !== "0" &&
                                            setFieldValue("correct_answer_id", e.target.value)
                                        }}>
                                            <FieldArray name='optionsArray'
                                                render={(arrayHelpers) => (
                                                    <Space direction="vertical">
                                                        {
                                                            values.optionsArray?.map((localOption: any, index: number) => (
                                                                <Row className="answer-row">
                                                                    <Col span={13} className="answer-input">
                                                                        <Radio value={localOption?.id}/>
                                                                        <InputField className='answer-input-field' name={`optionsArray.${index}.text`}
                                                                            placeholder={`Option ${index + 1}`}
                                                                            onFocus={(e: any) => setSelectedIndex(index)}
                                                                            onInput={(e: any) => {
                                                                                setLoadingOption(index)
                                                                            }}
                                                                            onBlur={(e: any) => {
                                                                                localOption?.id?.[0] != "0" ?
                                                                                    (
                                                                                        updateOption(convertJSONToFormData({ "option": { text: e.target.value } }), values.optionsArray[index].id, index,
                                                                                            (newOption: OptionModel, index: number) => {
                                                                                                setFieldValue(`optionsArray.${index}`, newOption)
                                                                                            },
                                                                                            () => { })
                                                                                    ) :
                                                                                    (
                                                                                        createOption(
                                                                                            e.target.value, index,
                                                                                            (newOption: OptionModel, index: number) => {
                                                                                                setFieldValue(`optionsArray.${index}`, newOption)
                                                                                            },
                                                                                            () => { })
                                                                                    )
                                                                            }} />
                                                                    </Col>
                                                                    <Col span={2}>
                                                                        <Field
                                                                            as={Upload}
                                                                            multiple={false}
                                                                            maxCount={1}
                                                                            onChange={(info: any) => {
                                                                                const { status } = info.file;
                                                                                if (status !== 'uploading' && status !== "removed") {
                                                                                    // setFieldValue(`optionsArray.${index}.imageUrl`, info.file)
                                                                                    setFieldValue(`option_${index + 1}_imageUrl`, info.file)
                                                                                    localOption.id?.[0] !== "0" ?
                                                                                        updateOption(convertJSONToFormData({ "option": { text: localOption?.text, image: info?.file } }), values.optionsArray[index].id, index,
                                                                                            (newOption: OptionModel, index: number) => {
                                                                                                setFieldValue(`optionsArray.${index}`, newOption)
                                                                                            },
                                                                                            () => { }) :
                                                                                        createOption(
                                                                                            "", index,
                                                                                            (newOption: OptionModel, index: number) => {
                                                                                                setFieldValue(`optionsArray.${index}`, newOption)
                                                                                            },
                                                                                            () => { }, info?.file)
                                                                                }
                                                                                else {
                                                                                    setFieldValue(`option_${index + 1}_imageUrl`, null)
                                                                                    updateOption(convertJSONToFormData({ "option": { text: localOption?.text, image: null } }), values?.optionsArray[index].id, index,
                                                                                    (newOption: OptionModel, index: number) => {
                                                                                        setFieldValue(`optionsArray.${index}`, newOption)
                                                                                    },
                                                                                    () => { })
                                                                                    // setFieldValue(`optionsArray.${index}.imageUrl`, null)
                                                                                }
                                                                            }}
                                                                            accept="image/*"
                                                                            name={`option_${index + 1}_imageUrl`}
                                                                            beforeUpload={() => false}
                                                                        >
                                                                            <i className="icon-image"></i>
                                                                        </Field>
                                                                    </Col>

                                                                    <Col span={2}>

                                                                        <i className="icon-delete" onClick={() => {
                                                                            localOption?.id == values?.correct_answer_id && setFieldValue("correct_answer_id", null)
                                                                            setFieldValue("optionsArray", values.optionsArray?.filter((data: any, i: number) => i !== index))
                                                                        }}></i>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                    </Space>
                                                )} />

                                        </Radio.Group>
                                    </Col>
                                </Row>
                                {
                                    values.optionsArray?.length < 5 ?
                                        <Button type="primary" onClick={() => {
                                            if (values.optionsArray.length < 5) {
                                                setFieldValue("optionsArray", [...values.optionsArray, { "text": null, "image": "null", "id": '0' + values.optionsArray?.length }])
                                            }
                                        }}>Add Answer</Button>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="create__form-actions">
                            <Button type="text" onClick={() => {
                                props.onClose();
                                topicId ?
                                    LocalStorage.removeItem("selected-topic") :
                                    LocalStorage.removeItem("selected-test");
                                window.location.pathname.split('/')[2] !== undefined && history.goBack()
                            }}>Cancel</Button>
                            <Button 
                                type="primary"
                                disabled={!isValid || !dirty}
                                loading={createQuestionLoading || loading}
                                htmlType="submit">Save</Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <SuccessModal
                modalClassName="contibute-more-modal"
                successModalVisible={successModalVisible}
                closeSuccessModal={() => {
                    onClose()
                    setSuccessModalVisible(false);
                    LocalStorage.removeItem("selected-topic");
                    LocalStorage.removeItem("selected-test");
                    (window.location.pathname.split('/')[2] !== undefined && window.location.pathname.split('/')[1] !== "manage-hiringtests" || window.location.pathname.split('/')[3] === "add-question") && history.goBack()
                }}
                maskClosable={maskClosable}
                successText={'Your have successfully contributed your question.'}
                component={!addNoMore && <>
                    <p>Do you want to continue adding questions</p>
                    <Button type="primary"
                        className="mr-2"
                        onClick={() => {
                            topicId ?
                                LocalStorage.setItem("selected-topic", topicId) :
                                LocalStorage.setItem("selected-test", testId)
                            onClose()
                            setSuccessModalVisible(false)
                            isQuestionView && history.goBack()
                        }}>Yes</Button>
                    <Button type="primary" onClick={() => {
                        onClose();
                        LocalStorage.removeItem("selected-topic")
                        LocalStorage.removeItem("selected-test")
                        setSuccessModalVisible(false)
                        isQuestionView && history.goBack()
                    }}>No</Button>
                </>}
            />
        </Fragment>
    )
}

export default NewQuestionForm;
