import React, { useEffect, useRef } from 'react'
import { Button, Row } from 'antd'
import {
    LeftOutlined
} from '@ant-design/icons'
import { useHistory } from 'react-router'
import './certificate.scss'
import badgeImage from './../../../assets/images/certification.png'
import TestService from '../../../services/TestService/test.service'
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';
import copyToClipboard from '../../../shared/utils/copyToClipBoard'
import AuthContainer from '../../../store/container/AuthContainer'
import Notification from '../../../shared/components/Notification'
import { NotificationTypes } from '../../../enums/notificationTypes'

const doc = new jsPDF({
    orientation: "landscape",
    format: [297, 174]
});

const Certificate = (props: any) => {
    const { authenticated,user } = props
    const history = useHistory()
    const { getCertificate, userCertificate, getCertificateMeta, userCertificateMeta, loading, error } = TestService()
    const userId = window.location.pathname.split('/')[4]
    const testId = window.location.pathname.split('/')[2]
    const certificateData = authenticated ? userCertificate : userCertificateMeta

    const certificateRef = useRef<HTMLDivElement>(null)

    const downloadPdf = () => {
        if (certificateRef.current === null) {
            return
        }
        htmlToImage.toCanvas(certificateRef.current)
            .then(function (blob: any) {
                // var blobPdf = new Blob([doc.output('blob')],{type:'application/pdf'})
                // var blobUrl = URL.createObjectURL(blobPdf)
                // const imgProps = doc.getImageProperties(blob);
                // const pdfWidth = doc.internal.pageSize.getWidth();
                // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                doc.addImage(blob, 'png', 0, 0, 297, 174)
                doc.save(`${userCertificate?.name} - ${userCertificate?.userName}.pdf`);
                // saveAs(blob, `${userCertificate?.name} - ${userCertificate?.userName}`);
            });
    }

    useEffect(() => {
        authenticated?
        getCertificate(testId):
        getCertificateMeta(testId,userId)
    }, [])

    return (
        <div className={`${authenticated ? "app-wrapper" : "share-view"} certificate__view`}>
            {
                authenticated &&
                <Row className="certificate__view-header">
                    <div className="goback">
                        <LeftOutlined onClick={() => history.goBack()} />
                        <p>Back</p>
                    </div>
                    <div className="actions">
                        <Button className="outlined" onClick={() =>{
                            copyToClipboard(`${window.location.origin+window.location.pathname.replace('earn-a-certificate','view-certificate')}/${user?.id}`)
                            Notification({
                                message: "Link Copied",
                                description: "",
                                type: NotificationTypes.SUCCESS,
                            });
                            }}>Share</Button>
                        <Button className="outlined" onClick={downloadPdf}>Download</Button>
                    </div>
                </Row>
            }
            {
                certificateData &&
                <Row className="certificate__view-card" ref={certificateRef} id='certificate'>
                    <div className="certificate__view-card-header">
                        <div className="banner">
                            <i className="icon-certificate"></i>
                            <h3>Tiger Testing</h3>
                            <h1>CERTIFICATE</h1>
                            <h2>OF COMPLETION</h2>
                        </div>
                        <div className="certificate-number">
                            <p>Certificate number: {certificateData?.id}</p>
                        </div>
                    </div>
                    <div className="certificate__view-card-info">
                        <div className="banner">
                            <p>Vivamus eget aliquam dui. Integer eu arcu vel arcu suscipit ultrices quis non mauris. Aenean scelerisque, sem eu dictum commodo, velit nisi blandit magna, quis scelerisque ipsum lectus ut libero.</p>
                            <h3>{certificateData?.name}</h3>
                            <div className="">
                                <i className="icon-success"></i>
                                <p>Completed</p>
                            </div>
                            <h2>{certificateData?.userName}</h2>
                        </div>
                        <div className="signatures">
                            <div className="date">
                                <h3>Date</h3>
                                <h3>{certificateData?.passedAt?.slice(0, 10).replaceAll('-', '/') || "5/17/2021"}</h3>
                            </div>
                            <div className="badge">
                                <img src={badgeImage} alt="" />
                            </div>
                            <div className="sign">
                                <h3>Signature</h3>
                                <h3>{certificateData?.testCreatedBy || "Sanders james"}</h3>
                            </div>
                        </div>
                    </div>
                </Row>
            }
        </div>
    )
}

export default AuthContainer(Certificate)
