import * as Yup from "yup";

export const testValidation = Yup.object().shape({
    timePerQuestion: Yup.number()
        .positive('Time per question must be positive!')
        .required('Time per question is required!'),
})

export const questionsValidation = (noOfQuestions: any) => Yup.object().shape({
    numberOfQuestions: Yup.number()
        .positive('Number of questions must be positive!')
        // .min(10, 'Number of questions must be greater than 10')
        .max(noOfQuestions, 'Number of questions can\'t exceed the number of available questions')
        .required('Number of questions is required!'),
})