import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, Table, Menu, Tabs, Button, Input, Modal } from 'antd'
import { useHistory, useRouteMatch, useLocation, useParams, generatePath } from 'react-router'
import {
    LeftOutlined
} from '@ant-design/icons'
import './viewhiringtest.scss'
import ManageQuestions from '../ManageQuestions'
import * as appRoutes from '../../../routes/routeConstants/appRoutes'
import TestService from '../../../services/TestService/test.service'
import { QuestionTypes } from '../../../enums/questionTypes'
import noDataImage from './../../../assets/images/no-data.png'
import questionImage from './../../../assets/images/question.png'
import {
    SearchOutlined,
    CheckCircleTwoTone,
    ClockCircleOutlined
} from '@ant-design/icons'
import { debounce } from "lodash";
import ParagraphEllipsis from '../../../shared/components/ParagraphEllipsis'
import NewQuestionDrawer from '../../../shared/components/NewQuestionDrawer'
import InputField from '../../../shared/components/InputField'
import { Form, Formik, FormikValues } from 'formik'
import SuccessModal from '../../../shared/components/SuccessModal'
import ActionModal from '../../../shared/components/ActionModal'
import PickerField from '../../../shared/components/Picker'
import QuestionService from '../../../services/QuestionService/question.service'
import { questionsValidation, testValidation } from './testValidation'
import Notification from './../../../shared/components/Notification'
import { NotificationTypes } from '../../../enums/notificationTypes'
import { TestTypes } from '../../../enums/testTypes'
import LocalStorage from '../../../shared/localStorage'
import copyToClipboard from '../../../shared/utils/copyToClipBoard'

const { TabPane } = Tabs;

const ViewHiringTest = (props: any) => {
    const location = useLocation()
    const { testId, testStatus, testType, encryptedTestId } = props.location.state
    const { getTestDashboardDetails, testDashboard, getTestAttempts, getCertificateTestAttempts, certificateTestAttempts, testAttempts, getTestUserAnswers, testUserAnswers, error, loading, editHiringTest, test } = TestService()
    const { questions, getTestQuestions, getTestQuestion, getNumberOfTestQuestions, numberOfQuestions, question, getParticularQuestion } = QuestionService()
    const module = window.location.pathname.split('/')[1]
    const history = useHistory()
    const [render, setRender] = useState(false)
    const [testView, setTestView] = useState<"dashboard"|"manage-questions">(location.hash?.replace(/#/gi, "") as "dashboard"|"manage-questions")
    const [deployTestQuestions, setDeployTestQuestions] = useState("all")
    const [newQuestionModalVisible, setNewQuestionModalVisible] = useState(false);
    const [deployTestModalVisible, setDeployTestModalVisible] = useState(false);
    const [ceritificateSuccessModalVisible, setCeritificateSuccessModalVisible] = useState(false)
    const [editTimeVisible, setEditTimeVisible] = useState(false);
    const [contributionLinkShareVisible, setContributionLinkShareVisible] = useState(false)

    const linkCopyComponent = <div className="take-hiringtest__link deploy_hiringtest_wrapper-link">
        <Formik
            initialValues={{ link: `${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${test?.encryptedTestId}` }}
            onSubmit={() => { }}
        >
            <Form className="link__form">
                <Row gutter={[12, 12]}>
                    <Col span={21}>
                        <InputField type="text" name="link" placeholder="Paste link here" className="link" />
                    </Col>
                    <Col span={3}>
                        <Button type="primary" className="blue" htmlType="submit" onClick={(e) => {
                            e.stopPropagation()
                            copyToClipboard(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${test?.encryptedTestId}`);
                            // navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${test?.id}`);
                            Notification({
                                message: "Link copied",
                                description: "",
                                type: NotificationTypes.SUCCESS,
                            });
                        }}>Copy</Button>
                    </Col>
                </Row>
            </Form>
        </Formik>
    </div>

    const openCertificateSuccessModal = () => {
        setCeritificateSuccessModalVisible(true)
    }

    const closeCertificateSuccessModal = () => {
        setCeritificateSuccessModalVisible(false)
        history.goBack()
    }

    const handleTestView = (e: any) => {
        history.push({pathname: generatePath(appRoutes.VIEW_HIRINGTEST,{testId}),  state : {testId, testStatus,encryptedTestId, testType}, hash: e.key})
        setTestView(e.key)
    }
    const handleDeployTestQuestions = (e: any) => {
        setDeployTestQuestions(e)
    }

    const openEditTimeModal = () => {
        setEditTimeVisible(true)
    }
    const closeEditTimeModal = () => {
        setEditTimeVisible(false)
    }

    const openContributionLinkModal = () => {
        setContributionLinkShareVisible(true)
    }
    const closeContributionLinkModal = () => {
        setContributionLinkShareVisible(false)
    }

    const optionImage = (element: any) => element?.imageUrl !== null
    const isGrid = question?.options?.some(optionImage)

    useEffect(() => {
        getTestDashboardDetails(testId)
        // CHANGED
        // testType === TestTypes.CERTIFICATE ?
        //     getCertificateTestAttempts(testId) :
            getTestAttempts(testId);
        getTestQuestions({ test_id: testId })
    }, [])

    useEffect(() => {
        if (questions?.questions?.length) {
            getParticularQuestion(`${questions?.questions?.[0].id}`)
        }
    }, [questions])

    useEffect(() => {
        getTestQuestions({ test_id: testId })
    }, [newQuestionModalVisible])

    const allQuestioncolumns = [
        {
            title: '',
            dataIndex: 'id',
            render: ((_: any, row: any) => <p className={`${question?.id == row?.id ? "bold" : ""}`}>{row?.id}</p>)
        },
        {
            title: '',
            dataIndex: 'title',
            render: ((_: any, row: any) => <ParagraphEllipsis className={`${question?.id === row?.id ? "bold" : ""}`} text={row?.title}></ParagraphEllipsis>)
            // render: ((_: any, row: any) => <Paragraph className={`${"question?.id" == row?.id ? "bold" : ""}`} ellipsis={ellipsis ? { rows: 1, expandable: false, symbol: 'more' } : false}>{row?.title}</Paragraph>)
        },
    ];
    const columns =
            [
                {
                    title: 'USER ID',
                    dataIndex: 'userId',
                },
                {
                    title: 'EMAIL',
                    dataIndex: 'email',
                },
                {
                    title: 'DATE',
                    dataIndex: 'startsAt',
                },
                {
                    title: 'CORRECT/QUESTIONS',
                    dataIndex: 'score',
                    render: ((_: any, row: any) => (
                        <>
                            <p className="report">{row?.score}/{row?.maxScore}</p>
                            <div className="">View Report</div>
                        </>
                    ))
                },
            ];

    const editTime = (values: any) => {
        editHiringTest(values, testId).then(() => {
            getTestDashboardDetails(testId)
            closeEditTimeModal()
        })
    }

    const deployTest = (values: FormikValues) => {

        editHiringTest(deployTestQuestions === "all" ? { "allQuestions": true, "status": "active" } : { ...values, "status": "active" }, testId).then(() => {
            getTestDashboardDetails(testId)
            setDeployTestModalVisible(false)
            openCertificateSuccessModal()
        })
    }

    let match = useRouteMatch();
    const handleSearch = useCallback(debounce(() => { }, 300, {}), [])

    useEffect(() => {
        setTimeout(() => {
            (LocalStorage.getItem("selected-topic") || LocalStorage.getItem("selected-test")) &&
                setNewQuestionModalVisible(true)
        }, 500);
    }, [loading, newQuestionModalVisible])

    return (
        <div className="app-wrapper test__view">
            <Row className="test__view-banner">
                <div className="title__container">
                    <div className="title">
                        <LeftOutlined onClick={history.goBack} />
                        {/* <ParagraphEllipsis expandable={true} symbol={'Read more'} text={testDashboard?.name as string}></ParagraphEllipsis> */}
                        <h1 onClick={history.goBack}>
                            {testDashboard?.name}
                        </h1>
                    </div>
                    <div className="info">
                        <p>Expiry date: {testDashboard?.expiryDate}</p>
                        <p>Time per question: {testDashboard?.timePerQuestion} sec</p>
                        {
                            testStatus === "active" && testType === "custom" &&
                            <Button className="transparent-round" onClick={openEditTimeModal}><i className="icon-edit-2"></i></Button>
                        }
                    </div>
                </div>
                <div className={`active-actions ${testStatus}`}>
                    {testStatus === "active" || testStatus === "expired" ?
                        <>
                            {
                                testView === "manage-questions" || testType === "certificate" ?
                                    <Button disabled={testStatus === "expired" ? true : false} className="transparent" onClick={(e) => {
                                        e.stopPropagation()
                                        copyToClipboard(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${encryptedTestId}`);
                                        // navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${testDashboard?.id}`);
                                        Notification({
                                            message: "Link copied",
                                            description: "",
                                            type: NotificationTypes.SUCCESS,
                                        });
                                    }}><i className="icon-copy-link"></i></Button> :
                                    testType === "custom" && testStatus !== "expired" &&
                                    <Button type="primary" className="blue" onClick={openContributionLinkModal}>Ask someone to Contribute</Button>
                            }
                            <Button type="primary" className="transparent">{testStatus}</Button>
                        </> :
                        null
                        // <Button className="invisible"><i className="icon-edit-2"></i></Button>
                    }
                </div>
                <div className="footer">
                    {
                        (testStatus === "active" || testStatus === "expired") && testType === "custom" ?
                            <Menu
                                onClick={handleTestView}
                                selectedKeys={[location.hash?.replace(/#/gi, "")]}
                                mode="horizontal"
                                className=""
                            >
                                <Menu.Item key="dashboard">
                                    <div className="">Dashboard</div>
                                </Menu.Item>
                                <Menu.Item key="manage-questions">
                                    <div className="">Manage - Questions</div>
                                </Menu.Item>
                            </Menu> :
                            testType === "custom" &&
                            <Button
                                // TODO change reuired from client
                                // disabled={questions?.questions && questions?.questions?.length < 10 ? true : false}
                                className="blue"
                                onClick={() => {
                                    getNumberOfTestQuestions({ test_id: testId, status: "approved" })
                                    setDeployTestModalVisible(true)
                                }
                                }>Deploy Test</Button>
                    }
                </div>
            </Row>
            <div className="test__view-content">
                {
                    testStatus === "active" || testStatus === "expired" ?
                        <>
                            {
                                testView === "dashboard" ?
                                    <>
                                        <div className="test__view-numbers">
                                            <Col>
                                                <h1>{testDashboard?.noOfAttempts}</h1>
                                                <p>ATTEMPTS</p>
                                            </Col>
                                            <Col>
                                                <h1>{testDashboard?.noOfTestsCompleted}</h1>
                                                <p>TEST COMPLETED</p>
                                            </Col>
                                            <Col>
                                                <h1>{testDashboard?.usersScoreFiftyPercentHigher}</h1>
                                                <p>USERS &gt; 50%</p>
                                            </Col>
                                            <Col>
                                                <h1>{testDashboard?.usersScoreFiftyPercentLower}</h1>
                                                <p>USERS &lt; 50%</p>
                                            </Col>
                                        </div>
                                        <div className={`test__view-table antd-table-full ${testType}`}>
                                            <Table
                                                loading={loading}
                                                onRow={(record, index) => ({
                                                    onClick: (event) => {
                                                        history.push(`${match.url}/${record?.id}`, { "userId": record?.id, "email": record?.email, "type": testType })
                                                    }
                                                })}
                                                columns={columns}
                                                // CHANGED
                                                // dataSource={testType === TestTypes.CERTIFICATE ? certificateTestAttempts?.users : testAttempts?.attempts}
                                                dataSource={testAttempts?.attempts}
                                                size="middle"
                                                pagination={false}
                                                scroll={{ y: 350 }} />
                                        </div>
                                    </> :
                                    <>
                                        <div className="test__view-menubar">
                                            {
                                                testStatus !== "expired" &&
                                                <Button type="primary" className="add-question" onClick={() => history.push(`${match.url}/add-question`, { "testId": testId })}>Add Question</Button>
                                            }
                                            <Tabs defaultActiveKey="1" onChange={() => { }} onTabClick={(key, event) => {
                                                setRender(state => !state)
                                            }}>
                                                <TabPane tab="My Questions" key="1">
                                                    <ManageQuestions testId={`${testId}`} render={render} />
                                                </TabPane>
                                                <TabPane tab="Approved" key="2">
                                                    <ManageQuestions testId={`${testId}`} type={QuestionTypes.APPROVED} render={render} />
                                                </TabPane>
                                                <TabPane tab="Pending Approval" key="3">
                                                    <ManageQuestions testId={`${testId}`} type={QuestionTypes.PENDING_APPROVAL} render={render} />
                                                </TabPane>
                                                <TabPane tab="Rejected" key="4">
                                                    <ManageQuestions testId={`${testId}`} type={QuestionTypes.REJECTED} render={render} />
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </>
                            }
                        </> :
                        <>
                            {
                                questions?.questions?.length ?
                                    <div className="all-questions contributed-questions">
                                        <Button type="primary" onClick={() => { setNewQuestionModalVisible(true) }}>Add Question</Button>
                                        <div className="questions__list">
                                            <div className="search-box">
                                                <Input placeholder="Search" name="search" type="text" addonAfter={<SearchOutlined onClick={handleSearch} />} />
                                                <Table
                                                    loading={loading}
                                                    onRow={(record, index) => ({
                                                        onClick: (event) => getParticularQuestion(`${record?.id}`)
                                                    })}
                                                    columns={allQuestioncolumns}
                                                    dataSource={questions?.questions}
                                                    size="middle"
                                                    pagination={false}
                                                    scroll={{ y: 450 }} />
                                            </div>
                                        </div>
                                        <div className="question__view">
                                            {
                                                question ?
                                                    <>
                                                        <div className="question__view-question">
                                                            <p style={{ marginBottom: "15px" }}>Question</p>
                                                            <p className="question-text">{question?.title}</p>
                                                            {
                                                                question?.imageUrl &&
                                                                <img src={question?.imageUrl} alt="" />
                                                            }
                                                        </div>
                                                        <div className="question__view-answer">
                                                            <p>Answers</p>
                                                            <div className={isGrid ? "answers_grid" : ""}>
                                                                {
                                                                    question?.options?.map((answer) => (
                                                                        // answer?.imageUrl ?
                                                                        <div className="answer_item">
                                                                            <h3>{answer?.text}</h3>
                                                                            {
                                                                                answer?.imageUrl &&
                                                                                <img src={answer?.imageUrl} alt="" />
                                                                            }
                                                                        </div>
                                                                        // :
                                                                        // <h3>{answer?.text}</h3>
                                                                    ))
                                                                }
                                                            </div>
                                                            <p style={{ marginTop: "15px" }}>Correct Answer</p>
                                                            {question?.correctAnswer?.text &&
                                                                <h3>
                                                                    <CheckCircleTwoTone twoToneColor="#40CE2A" />
                                                                    <span>{question?.correctAnswer?.text}</span>
                                                                </h3>
                                                            }
                                                            <img className="correct_answer_image" src={question?.correctAnswer?.imageUrl} alt="" />
                                                        </div>
                                                    </> :
                                                    null
                                            }
                                        </div>
                                    </div> :
                                    <div className="no-questions">
                                        <img src={noDataImage} alt="" />
                                        <h3>No questions added yet</h3>
                                        <Button type="primary" onClick={() => setNewQuestionModalVisible(true)}>Add Question</Button>
                                    </div>
                            }
                        </>
                }
            </div>
            <NewQuestionDrawer maskClosable={false} testId={testId} visible={newQuestionModalVisible} onClose={() => setNewQuestionModalVisible(false)} />

            <ActionModal
                width={750}
                modalVisible={deployTestModalVisible}
                closeModal={() => {
                    setDeployTestModalVisible(false)
                }}
                modalClassName="deploy-test-modal app-modal "
                title={'Please enter the number of Questions for the Test.'}
                component={
                    <Formik
                        initialValues={{}}
                        onSubmit={deployTest}
                        validationSchema={deployTestQuestions === "limited" && questionsValidation(numberOfQuestions && numberOfQuestions)}
                    >
                        {({ values, setFieldValue }) => (
                            <Form className="deploy-test-modal-form">
                                <div className={`deploy-test-modal-form-fields ${testStatus}`}>
                                    <div className="menubar">
                                        <Tabs className="antd-whole-tab two-tone" defaultActiveKey="all" onChange={(e) => handleDeployTestQuestions(e)}>
                                            <TabPane tab="All Questions" key="all">
                                                <></>
                                            </TabPane>
                                            <TabPane tab="Limited Questions" key="limited">
                                                <InputField name="numberOfQuestions" type="number" placeholder="No of Questions" />
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                    <div className="note">
                                        <p>Note:</p>
                                        <p><span>All questions</span>- All approved questions will be used.</p>
                                        <p><span>Limited questions</span>- Test Questions will be randomly selected from the pool of Approved Questions. 10 minimum questions.</p>
                                    </div>
                                </div>
                                <div className="deploy-test-modal-form-actions">
                                    <Button type="text" onClick={() => setDeployTestModalVisible(false)}>Cancel</Button>
                                    <Button type="primary" htmlType="submit" onClick={() => {

                                    }}>Deploy Test</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                } />

            <ActionModal
                width={550}
                modalVisible={editTimeVisible}
                closeModal={() => {
                    closeEditTimeModal()
                }}
                modalClassName="edit-time-modal"
                title={'Edit Time'}
                component={
                    <Formik
                        initialValues={{}}
                        onSubmit={editTime}
                        validationSchema={testValidation}
                    >
                        {({ values, setFieldValue }) => (
                            <Form className="edit-time-modal-form">
                                <div className={`edit-time-modal-form-fields ${testStatus}`}>
                                    <InputField name="timePerQuestion" type="number" placeholder="Time per question (in seconds)" addonAfter={<ClockCircleOutlined />} />
                                    {/* <PickerField name="time_per_question" placeholder="Expiry Time" pickType="time" setFieldValue={setFieldValue} /> */}
                                </div>
                                <div className="edit-time-modal-form-actions">
                                    <Button type="text" onClick={closeEditTimeModal}>Cancel</Button>
                                    <Button type="primary" htmlType="submit" onClick={() => {
                                    }}>Save</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                } />

            <ActionModal
                width={550}
                modalVisible={contributionLinkShareVisible}
                closeModal={() => {
                    closeContributionLinkModal()
                }}
                modalClassName="contribution-link-modal"
                title={'Contribution Code'}
                component={
                    <Formik
                        initialValues={{ code: testDashboard?.accessCode }}
                        onSubmit={() => { }}
                    >
                        <Form className="contribution-link-modal-form">
                            <Row gutter={[12, 12]} align="stretch" className="contribution-link">
                                <Col span={21}>
                                    <InputField type="text" name="code" placeholder="Paste link here" className="link" />
                                </Col>
                                <Col span={3}>
                                    <Button type="primary" htmlType="submit"><i className="icon-refresh"></i></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Button type="primary" className="blue" htmlType="submit" onClick={(e) => {
                                    e.stopPropagation()
                                    copyToClipboard(`${testDashboard?.accessCode}`);
                                    // navigator.clipboard.writeText(`${testDashboard?.accessCode}`);
                                    Notification({
                                        message: "Link copied",
                                        description: "",
                                        type: NotificationTypes.SUCCESS,
                                    });
                                }}>Copy Code</Button>
                            </Row>
                        </Form>
                    </Formik>
                } />

            <SuccessModal successModalVisible={ceritificateSuccessModalVisible} closeSuccessModal={() => {
                closeCertificateSuccessModal()
            }} successText={'Your test has been successfully deployed! Use this unique URL'} modalClassName={"link-copy-modal"} component={linkCopyComponent} />
        </div>
    )
}

export default ViewHiringTest
