
/* Auth Routes */
export const AUTH = "/auth"
export const REGISTER = AUTH + "/register"
export const LOGIN = AUTH + "/login"
export const EMAIL = AUTH + "/email"
export const VERIFY = AUTH + "/verify"
export const RESET = AUTH + "/reset"
export const TERMS_AND_CONDITIONS = AUTH+ "/terms-and-conditions"

/* Home Route */
export const HOME = "/home"
export const TOPICS = "/topics"
export const PROPOSE_TOPIC=TOPICS+"/propose-new-topic"
export const VIEW_TOPIC=TOPICS+"/:topicId"
export const CONTRIBUTE_TOPIC_QUESTION=VIEW_TOPIC+"/contribute-question"
export const VIEW_PROPOSED_TOPIC=TOPICS+"/proposed-topic/:topicId"
export const TAKE_A_TOPIC_TEST = TOPICS+"/instructions/:testId"

export const TAKE_HIRINGTEST = "/take-a-hiringtest"
export const TAKE_A_TEST = TAKE_HIRINGTEST+"/instructions/:testId"
export const TAKE_A_TEST_CERTIFICATE = TAKE_A_TEST+"/:testId"
export const START_A_TEST_VIEW = TAKE_A_TEST+"/start-test"

export const TAKE_A_TEST_VIEW = "/test/:testId"

export const DEPLOY_HIRINGTEST = "/deploy-a-hiringtest"
export const DEPLOY_CUSTOM_HIRINGTEST = DEPLOY_HIRINGTEST+'/custom'
export const DEPLOY_CERTIFICATE_HIRINGTEST = DEPLOY_HIRINGTEST+'/certificate'

export const MANAGE_HIRINGTESTS = "/manage-hiringtests"
export const VIEW_HIRINGTEST = MANAGE_HIRINGTESTS + "/:testId"
export const VIEW_HIRINGTEST_QUESTION = VIEW_HIRINGTEST + "/:id/view"
export const ADD_HIRINGTEST_QUESTION = VIEW_HIRINGTEST+"/add-question"
export const VIEW_HIRINGTEST_REPORT = VIEW_HIRINGTEST + "/:id"
export const VIEW_HIRINGTEST_REPORT_QUESTION = VIEW_HIRINGTEST_REPORT + "/:id"

export const EARN_CERTIFICATE = "/earn-a-certificate"
export const VIEW_CERTIFICATE = EARN_CERTIFICATE+"/:id/certificate"
export const VIEW_CERTIFICATE_META = "/view-certificate/:id/certificate/:id"

export const CONTRIBUTE_QUESTIONS = "/contribute-questions"
export const VIEW_QUESTION = CONTRIBUTE_QUESTIONS+"/:id"

export const PROFILE = "/profile"