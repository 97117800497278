import { Button } from 'antd'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import UserService from '../../../services/AuthService/auth.service'
import { registerSchema, validationSchema } from '../../../views/Auth/Validations'
import InputField from '../InputField'
import * as AppRoutes from './../../../routes/routeConstants/appRoutes'

const AuthForm = () => {
    const history = useHistory()
    const { search } = useLocation();
    const [isLILoading, setIsLILoading] = useState(false);
    const module = window.location.pathname.split('/')[2]
    const { user, error, loading, login, signUp, loginLinkedIn, signupLinkedIn } = UserService();

    const onSubmit = (user: any) => {
        module === "register" ?
            signUp(user) :
            login(user);
    }

    useEffect(() => {
        let query = new URLSearchParams(search);
        if (query?.get("code")) {
            setIsLILoading(true);
            module === "register" ?
                signupLinkedIn({authorizationCode:query?.get("code") ?? ""}) :
                loginLinkedIn({authorizationCode:query?.get("code") ?? ""})
            //   UserService.registerLinkedIn(
            //     {
            //       authorizationCode: query?.get("code") ?? "",
            //     },
            //     () => {
            //       history.push(appRoutes.HOME);
            //     },
            //     () => {},
            //     () => {
            //       setIsLILoading(false);
            //     }
            //   );
        }
        setIsLILoading(false)
    }, []);

    return (
        <Formik
            initialValues={{}}
            onSubmit={onSubmit}
            validationSchema={module === "register" ? registerSchema : validationSchema}
        >
            <Form className="auth-form">
                <InputField type="email" name="email" placeholder="Enter email" />
                {
                    module === "register" ?
                        <InputField type="password" name="password1" placeholder="Enter password" /> :
                        <InputField className='password-field' type="password" name="password" placeholder="Enter password" />
                }
                {
                    module === "register" ?
                        (
                            <div>
                                <InputField type="password" name="password2" placeholder="Confirm password" />
                                <p className="auth-text left">Please follow Password best practices and don't re-use passwords across applications!</p>
                            </div>
                        ) :
                        <Button type="text" onClick={() => history.push(AppRoutes.EMAIL)}>Forgot Password?</Button>
                }
                <Button htmlType="submit" type="primary">{
                    module === "register" ?
                        `Sign up` :
                        `Sign in`
                }</Button>
                <p className="auth-text center">or</p>
                <Button loading={isLILoading} className="auth-outlined"
                    onClick={() => {
                        module === "register" ?
                            window.open(
                                `${process.env.REACT_APP_LI_AUTHENTICATION_URL}?response_type=code&client_id=${process.env.REACT_APP_LI_CLIENT_ID}&redirect_uri=${window.location.origin}/auth/register&scope=r_liteprofile r_emailaddress`,
                                "_self"
                            ) :
                            window.open(
                                `${process.env.REACT_APP_LI_AUTHENTICATION_URL}?response_type=code&client_id=${process.env.REACT_APP_LI_CLIENT_ID}&redirect_uri=${window.location.origin}/auth/login&scope=r_liteprofile r_emailaddress`,
                                "_self"
                            )
                    }}
                >
                    <svg role="img" viewBox="0 0 24 24" fill="#0077b5" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" /></svg>
                    Continue with Linked in</Button>
            </Form>
        </Formik>
    )
}

export default AuthForm
