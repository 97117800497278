import React, { useEffect } from 'react'
import { Button, Col, Row } from 'antd'
import './certificatetests.scss'
import TestService from '../../../../services/TestService/test.service'
import { useHistory } from 'react-router-dom'
import * as appRoutes from './../../../../routes/routeConstants/appRoutes'
import { TestStatuses } from '../../../../enums/testStatuses'

const CertificateTests = () => {
    const { loading, error, topicTests, getTopicTests } = TestService()
    const topicId = window.location.pathname.split('/')[2]
    const history=useHistory()

    useEffect(() => {
        getTopicTests(topicId)
    }, [])
    return (
        <div className="certificate-tests">
            <Row gutter={[10, 10]}>
                {
                    topicTests?.tests?.map((test) => (
                        <Col xl={8} sm={12}>
                            <div className="certificate-tests-card">
                                <h2>{test?.name}
                                    <i className="icon-certificate"></i>
                                </h2>
                                <div className="footer">
                                    {
                                        test?.testStatus === TestStatuses.UNATTEMPTED || (test?.testStatus === TestStatuses.FAILED && test?.attemptsRemaining != 0)  ?
                                                <>
                                                    <Button type="primary" onClick={() => history.push(appRoutes.TAKE_A_TOPIC_TEST.replace(':testId', `${test?.encryptedTestId}`),test?.encryptedTestId)}>Take test</Button>
                                                    <h4 className="attempts">{test?.attemptsRemaining} attempts remaining</h4>
                                                </> :
                                                test?.attemptsRemaining == 0 && test?.testStatus !== TestStatuses.SUCCESS ?
                                                    <>
                                                        <Button type="primary" disabled>Take test</Button>
                                                        <h4 className="no-attempts">You've reached max attempts to take this test</h4>
                                                    </> :
                                                    <p> <i className="icon-correct-answer"></i> Completed</p>
                                    }
                                </div>
                            </div>
                        </Col>
                    ))
                }
                {/* <Col xl={8} sm={12}>
                    <div className="certificate-tests-card">
                        <h2>Web Development Fundamentals: Javascript
                            <i className="icon-certificate"></i>
                        </h2>
                        <div className="footer">
                            <Button type="primary">Take test</Button>
                            <h4 className="attempts">3 attempts remaining</h4>
                        </div>
                    </div>
                </Col>
                <Col xl={8} sm={12}>
                    <div className="certificate-tests-card">
                        <h2>Web Development Fundamentals: Javascript
                            <i className="icon-certificate"></i>
                        </h2>
                        <div className="footer">
                            <Button type="primary" disabled>Take test</Button>
                            <h4 className="no-attempts">You've reached max attempts to take this test</h4>
                        </div>
                    </div>
                </Col> */}
            </Row>
        </div>
    )
}

export default CertificateTests
