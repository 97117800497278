import React, { useState } from "react"
import { Button, Col, Row } from "antd";
import { Formik, Form, Field, FormikValues } from "formik";
import { topicValidation } from './topicValidation'

import InputField from "../../../shared/components/InputField";
import { Upload, Input } from 'antd';
import { useHistory } from "react-router";
// import TopicService from "../../../services/TopicService/topic.service";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { convertJSONToFormData } from "../../../shared/utils/formDataConvertor";
import './proposetopic.scss'
import SuccessModal from "../../../shared/components/SuccessModal";
import TopicService from "../../../services/TopicService/topic.service";

const { Dragger } = Upload;
const { TextArea } = Input;

const ProposeTopic = () => {

    const { createTopic, error, loading } = TopicService()
    const history = useHistory()
    const [successModalVisible, setSuccessModalVisible] = useState(false)
    const [url, setUrl] = useState("")

    const openSuccessModal = () => {
        setSuccessModalVisible(true)
    }

    const onSubmit = (values: FormikValues) => {
        createTopic(convertJSONToFormData({"topic":values}))
        if (error) {
            Notification({
                message: "Error",
                description: `Error occured while creating a topic, please try again!`,
                type: NotificationTypes.ERROR,
            });
        } else {
                openSuccessModal()
        }
    }

    return (
        <div className="app-wrapper propose__topic">
            <div className="propose__topic-header">
                <h1>Propose a Topic</h1>
                <Row className="notification-note" gutter={10}>
                    <Col span={2}>
                        <i className="icon-note"></i>
                    </Col>
                    <Col className="" span={22}>
                        <h4>Note:</h4>
                        <p>This will be taken for admin review and added to public topic list if valid and added to public topics when approved.</p>
                    </Col>
                </Row>
            </div>
            <div className="topic-form__container">
                <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validationSchema={topicValidation}
                >
                    {({ values, setFieldValue, dirty, isValid }) => (
                        <Form className="topic__form">
                            <div className="topic__form-fields">
                                <InputField type="text" name="name" placeholder="Topic name" />
                                <Field
                                    as={Dragger}
                                    onChange={(info: any) => {
                                        const { status } = info.file;
                                        if (status !== 'uploading' && status !== "removed") {
                                            setFieldValue("image", info?.file)
                                            setUrl(window.URL.createObjectURL(info?.file))
                                        } else {
                                            setFieldValue("image", "")
                                            setUrl("")
                                        }
                                    }}
                                    onDrop={(e: any) => {
                                        setFieldValue("image", e.dataTransfer.files[0])
                                    }}
                                    accept="image/*"
                                    name="image"
                                    beforeUpload={() => false}
                                    multiple={false}
                                >
                                    {url !== "" ?
                                        <img src={url} alt="" /> :
                                        null
                                    }
                                    <h3 className="ant-upload-text">
                                        <i className="icon-upload"></i>
                                        Upload image</h3>
                                </Field>
                                <Field
                                    as={TextArea}
                                    name="description"
                                    placeholder="Description"
                                    rows={1}
                                    maxLength={300}
                                />
                            </div>
                            <div className="topic__form-actions">
                                <Button type="text" onClick={history.goBack}>Cancel</Button>
                                <Button type="primary" htmlType="submit" disabled={!isValid || !dirty} onClick={()=>{
                                    setSuccessModalVisible(true)
                                    
                                }}>Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <SuccessModal successModalVisible={successModalVisible} closeSuccessModal={()=>{
                    setSuccessModalVisible(false)
                    history.goBack()
                }} successText={'Your new topic has been submitted successfully.'} />
            </div>
        </div>
    )
}

export default ProposeTopic
