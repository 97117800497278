import { Drawer } from 'antd'
import React, { useEffect } from 'react'
import LocalStorage from '../../localStorage'
import NewQuestionForm from '../NewQuestionForm'
import './newquestiondrawer.scss'

const NewQuestionDrawer = (props: {
    onClose: any,
    visible: boolean,
    topicId?: string,
    testId?: string,
    maskClosable?: boolean
}) => {
    return (
        <Drawer
            destroyOnClose={true}
            title={<h1>New Question</h1>}
            placement="right"
            width="73vw"
            onClose={() => {
                props.onClose();
                LocalStorage.removeItem("selected-topic");
                LocalStorage.removeItem("selected-test");
            }}
            visible={props.visible}
            closable={true}
            maskStyle={{
                opacity: .78,
                backgroundColor: "#000"
            }}
            className="filter-drawer"
        >
            <NewQuestionForm maskClosable={props.maskClosable} topicId={props.topicId} testId={props.testId} onClose={props.onClose} />
        </Drawer>
    )
}

export default NewQuestionDrawer
