import React, { useEffect, useState } from "react";
import { Button, Upload } from "antd";
import { Field, Form, Formik, FormikValues } from "formik";
import InputField from "../../shared/components/InputField";
import AuthContainer from "../../store/container/AuthContainer";
import './profile.scss'
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { CameraOutlined } from '@ant-design/icons'
import { convertJSONToFormData } from "../../shared/utils/formDataConvertor";
import SelectField from "../../shared/components/SelectField";
import ProfileService from "../../services/ProfileService";
import { validationSchema } from "./validationSchema";
import MetaService from "../../services/MetaService/meta.service";

const Profile = (props: any) => {
    const { loading, error, getProfile, profile, editProfile } = ProfileService()
    const { getCountriesMeta, countries } = MetaService()
    const [url, seturl] = useState("")
    // const countries = [{ name: "India", flag: "🇮🇳" }, { name: "UK", flag: "🇬🇧" }]
    const user = props.user
    const onSubmit = (values: FormikValues) => {
        typeof values?.image === "string" && delete values.image;
        delete values?.country;
        editProfile(convertJSONToFormData({ "user": values }))
        if (error) {
            Notification({
                message: "Profile",
                description: "Error updating profile",
                type: NotificationTypes.ERROR,
            });
        } else {
            Notification({
                message: "Profile",
                description: "Profile updated successfully",
                type: NotificationTypes.SUCCESS,
            });
        }
    }

    useEffect(() => {
        getProfile()
        getCountriesMeta()
    }, [])

    return (
        <div className="app-wrapper profile__view">
            <div className="profile__view-header">
                <h1>My Profile</h1>
            </div>
            <div className="profile__view-form">
                <Formik
                    initialValues={{ full_name: user?.fullName, email: user?.email, image: user?.imageUrl,country_id: user?.countryId,country: user?.countryName }}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="help__form">
                            <div className="fields">
                                <Field
                                    as={Upload}
                                    onChange={(info: any) => {
                                        const { status } = info.file;
                                        if (status !== 'uploading' && status !== 'removed') {
                                            setFieldValue("image", info?.file)
                                            seturl(URL.createObjectURL(info?.file))
                                        } else {
                                            setFieldValue("image", "")
                                            seturl("")
                                        }
                                    }}
                                    accept="image/*"
                                    name="image"
                                    beforeUpload={() => false}
                                >
                                    <div className="ant-upload-text">
                                        {
                                            user?.imageUrl && url === "" ?
                                                <img src={user?.imageUrl} alt="" /> :
                                                url !== "" ?
                                                    <img src={url} alt="" /> :
                                                    null
                                        }
                                        <div className="footer">
                                            <CameraOutlined />
                                            {/* <i className="icon-image"></i> */}
                                        </div>
                                    </div>
                                </Field>
                                <InputField type="text" name="full_name" placeholder="Full name" />
                                <InputField disabled={true} type="text" name="email" placeholder="Email" />
                                <SelectField name="country_id" dropdownClassName="deploy-hiringtest-select" size="middle" placeholder="Country of residence" setFieldValue={setFieldValue} countries={countries} defaultValue={values?.country} />
                                {/* <InputField type="text" name="job_title" placeholder="Job Title" /> */}
                            </div>
                            <div className="controls">
                                <Button type="primary" htmlType="submit">Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AuthContainer(Profile);
