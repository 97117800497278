import {serializable, alias, object, list, primitive} from 'serializr';

export class Option { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('text', primitive()))
	text?: string;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

	@serializable(alias('image', object(File)))
	image?: File;

}
export class QuestionAttachment { 

	@serializable(alias('image', primitive()))
	image?: string;

}
export class Tag { 

	@serializable(alias('0', primitive()))
	"0"?: string;

	@serializable(alias('1', primitive()))
	"1"?: string;

	@serializable(alias('2', primitive()))
	"2"?: string;

	@serializable(alias('3', primitive()))
	"3"?: string;

	@serializable(alias('4', primitive()))
	"4"?: string;

}

export class QuestionDefaultModel {
	
	@serializable(alias('id', primitive()))
	id?: number;
	
	@serializable(alias('title', primitive()))
	title?: string;
	
	@serializable(alias('question_Attachments', list(object(QuestionAttachment))))
	questionAttachments?: QuestionAttachment[] = [];
	
	@serializable(alias('correct_answer', object(Option)))
	correctAnswer?: Option;
	
	@serializable(alias('correct_answer_id', primitive()))
	correctAnswerId?: number;
	
	@serializable(alias('tags', list(primitive())))
	tags?: String[] = [];
	
	@serializable(alias('difficulty_level', primitive()))
	difficultyLevel?: string;
	
	@serializable(alias('status', primitive()))
	status?: string;
	
	@serializable(alias('is_active', primitive()))
	isActive?: string;
	
	@serializable(alias('created_by', primitive()))
	createdBy?: string;
	
	@serializable(alias('created_at', primitive()))
	createdAt?: string;
	
	@serializable(alias('no_of_times_used', primitive()))
	noOfTimesUsed?: number;
	
	@serializable(alias('topic_test', primitive()))
	topicTest?: string;
	
	@serializable(alias('type', primitive()))
	type?: string;
	
	@serializable(alias('image_url', primitive()))
	imageUrl?: string;
	
	@serializable(alias('image', object(File)))
	image?: File;
	
	@serializable(alias('option_image', primitive()))
	optionImage?: string;

}
export class QuestionCreationModel extends QuestionDefaultModel { 

	@serializable(alias('options', list(primitive())))
	options?: number[] = [];
}
export class QuestionViewModel extends QuestionDefaultModel { 

	@serializable(alias('options', list(object(Option))))
	options?: Option[] = [];
}

export class Questions{
    @serializable(alias("questions", list(object(QuestionViewModel))))
    questions?: QuestionViewModel[]=[];
}

export class CreateQuestionModel { 

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('difficulty_level', primitive()))
	difficultyLevel?: string;

	@serializable(alias('tags', list(primitive())))
	tags?: String[] = [];

	@serializable(alias('options', list(primitive())))
	options?: Number[] = [];

	@serializable(alias('correct_answer', primitive()))
	correctAnswer?: number;

}

export class Topic { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

}

export class Topics{
    @serializable(alias("topics", list(object(Topic))))
    topics?: Topic[]=[];
}

export class Test { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

}

export class Tests{
    @serializable(alias("tests", list(object(Test))))
    tests?: Test[]=[];
}

export class User { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('email', primitive()))
	email?: string;

}

export class Users{
    @serializable(alias("users", list(object(User))))
    users?: User[]=[];
}