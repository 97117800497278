import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers/index";
import * as AttemptFunctions from "../actions/attempt";

const mapStateToProps = (state: RootReducerProps) => ({
	valid: state.attempt.valid,
	attempt: state.attempt.attempt,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(AttemptFunctions, dispatch);

const AttemptContainer = (component: any) => {
	return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default AttemptContainer;
