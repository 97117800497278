import * as Yup from "yup";

export const certificateTestValidation = Yup.object().shape({
    // email: Yup.string()
    //   .email('E-mail is not valid!')
    //   .required('E-mail is required!'),
    testName: Yup.string()
      .required('Test name is required!'),
    expiryDate: Yup.string()
      .required('Expiry date is required!'),
  })

  export const customTestValidation = Yup.object().shape({
    testName: Yup.string()
      .required('Test name is required!'),
    timePerQuestion: Yup.number()
      .positive('Time per question must be positive!')
      .required('Time per question is required!'),
    expiryDate: Yup.string()
      .required('Expiry date is required!'),
  })