import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import { Option, QuestionCreationModel, QuestionDefaultModel, Questions, QuestionViewModel } from "../../models/Question/question.model";
import { convertJSONToFormData } from "../../shared/utils/formDataConvertor";

const QuestionService = () => {
    const [questions, setQuestions] = useState<Questions>();
    const [question, setQuestion] = useState<QuestionViewModel>();
    const [numberOfQuestions, setNoOfQuestions] = useState()
    const [newOption, setNewOption] = useState<any>();
    const [error, setError] = useState<Error>();
    const [questionError, setQuestionError] = useState<Error>();

    const [loading, setLoading] = useState(false);
    const [createQuestionLoading, setCreateQuestionLoading] = useState(false);

    const [questionEdited, setQuestionEdited] = useState(false)

    const getTopicQuestions = (topicId:string,status?:string,search?:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.TOPIC_CONTRIBUTED_QUESTIONS.replace(':topicId',topicId),{params:{"status":status,"search":search}})
            .then((response) => {
                const _questions = deserialize(Questions, response.data);
                setQuestions(_questions);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTopicQuestion = (topicId:string,questionId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.TOPIC_CONTRIBUTED_QUESTION.replace(':topicId',topicId).replace(':questionId',questionId))
            .then((response) => {
                const _questions = deserialize(QuestionViewModel, response.data["questions"][0]);
                setQuestion(_questions);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editTestQuestion = (questionId: any,question:FormData|{"status":String}|{"is_active":boolean}) => {
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.GET_PARTICULAR_QUESTION.replace(":questionId",questionId),question)
            .then((response) => {
                setQuestionEdited(true)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getContributedQuestions = (options?:{
        topic_ids?: String[],
        statuses?: String[],
        test_ids?: String[],
        created_by_ids?: String[],
        types?: String[],
        questions_title_desc?:boolean,
        topic_test_name_desc?:boolean,
        created_by_desc?:boolean,
        date_desc?:boolean
      }) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_CONTRIBUTED_QUESTIONS)
            .then((response) => {
                const _questions = deserialize(Questions, response.data);
                setQuestions(_questions);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getContributedQuestion = (id:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_CONTRIBUTED_QUESTION.replace(':questionId',id))
            .then((response) => {
                const _question = deserialize(QuestionViewModel, response.data);
                setQuestion(_question);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestQuestions = (options:{
        status?: string,
        test_id: string
    }) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.CREATE_TEST_QUESTION.replace(":testId",options?.test_id),{params:options})
            .then((response) => {
                const _questions = deserialize(Questions, response.data);
                setQuestions(_questions);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getNumberOfTestQuestions = (options:{
        status?: string,
        test_id: string
    }) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.CREATE_TEST_QUESTION.replace(":testId",options?.test_id),{params:options})
            .then((response) => {
                // const _questions = deserialize(Questions, response.data);
                setNoOfQuestions(response.data.questions.length);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestQuestion = (testId: string,questionId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TEST_QUESTION.replace(":testId",testId).replace(":questionId",questionId))
            .then((response) => {
                const _question = deserialize(QuestionViewModel, response.data);
                setQuestion(_question);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createTestQuestion = (question: QuestionCreationModel, testId:string, onSuccess: Function, onError: (error:any)=>void) => {
        setCreateQuestionLoading(true);
        // TODO serialization
        // const payload = serialize(QuestionCreationModel, question)
        const data = convertJSONToFormData({ "question": question })
        return axiosInstance
        .post(ApiRoutes.CREATE_TEST_QUESTION.replace(":testId",testId),data,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response) => {
            onSuccess()
        })
        .catch((error) => {
            onError(error)
            setQuestionError(error);
        })
        .finally(() => {
                setCreateQuestionLoading(false);
            });
        };
        
        const createTopicQuestion = (question: QuestionCreationModel,topicId:string, onSuccess: Function, onError: (error:any)=>void) => {
            setCreateQuestionLoading(true);
            // TODO serialization
            // const payload = serialize(QuestionCreationModel, question)
            const data = convertJSONToFormData({ "question": question })
            return axiosInstance
            .post(ApiRoutes.CREATE_TOPIC_QUESTION.replace(":topicId",topicId),data,{headers:{'Content-Type':'multipart/form-data'}})
            .then((response) => {
                onSuccess()
            })
            .catch((error) => {
                onError(error)
                setQuestionError(error);
            })
            .finally(() => {
                setCreateQuestionLoading(false);
            });
    };

    const createOption = (text: String,index:number,onSuccess:Function,onError:Function,image?:any) => {
        const _option= convertJSONToFormData({"option":{text:text,image:image}}) 
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.CREATE_OPTION,_option)
            .then((response) => {
                const newOption=deserialize(Option,response.data["option"])
                onSuccess(newOption,index)
                setNewOption(newOption)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editOption = (text: String) => {
        const _option={
            "option":{
                text,
                image:""
            }
        }
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.UPDATE_OPTION,_option)
            .then((response) => {
                const newOption=deserialize(Option,response.data["option"])
                setNewOption(newOption)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateOption = (option: FormData,optionId:any,index:number,onSuccess:Function,onError:Function) => {
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.UPDATE_OPTION.replace(":id",optionId),option,{headers:{'Content-Type':'multipart/form-data'}})
            .then((response) => {
                const newOption=deserialize(Option,response.data["option"])
                onSuccess(newOption,index)
                setNewOption(newOption)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getParticularQuestion = (questionId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_PARTICULAR_QUESTION.replace(":questionId",questionId))
            .then((response) => {
                const _question = deserialize(QuestionViewModel, response.data["question"]);
                setQuestion(_question);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return {
        questions,
        error,
        loading,
        getTopicQuestions,
        createTopicQuestion,
        createTestQuestion,
        newOption,
        createOption,
        editOption,
        updateOption,
        getTopicQuestion,
        question,
        getContributedQuestions,
        getContributedQuestion,
        getTestQuestions,
        getTestQuestion,
        getParticularQuestion,
        editTestQuestion,
        getNumberOfTestQuestions,
        numberOfQuestions,
        questionEdited,
        questionError,
        createQuestionLoading
    };
};

export default QuestionService;
