import React from 'react'
import { Modal } from 'antd'
import {
    CloseOutlined,
    ExclamationOutlined
} from '@ant-design/icons'
import './successmodal.scss'

const SuccessModal = (props: {
    successModalVisible: any,
    closeSuccessModal: any,
    failure?: boolean,
    exception?: boolean,
    successText: string,
    result?: string,
    component?: any,
    modalClassName?: string,
    width?: number,
    successHeader?: string,
    subSuccessText?: string,
    maskClosable?: boolean
}) => {
    const { successModalVisible, closeSuccessModal, failure, exception, successHeader, subSuccessText, successText, result, component, modalClassName, width, maskClosable } = props
    return (
        <Modal
            width={width}
            className={`success-modal ${modalClassName}`}
            centered
            title={
                failure ?
                    <>
                        <CloseOutlined />
                        <h2>Sorry!</h2>
                    </> :
                    exception ?
                        <>
                            <ExclamationOutlined />
                            <h2>Oops Sorry!</h2>
                        </> :
                        <>
                            <i className="icon-success"></i>
                            <h2>{successHeader ? successHeader : "Success!"}</h2>
                        </>
            }
            visible={successModalVisible}
            onCancel={closeSuccessModal}
            maskClosable={maskClosable ? true : false}
            footer={null}>
            <h3>{successText}</h3>
            {
                subSuccessText &&
                <h2>"{subSuccessText}"</h2>
            }
            {result && <h4>Your Result: <span className={`${failure ? 'failure' : 'success'}`}>{result}</span> </h4>}
            {component}
        </Modal>
    )
}

export default SuccessModal
