import { Button } from 'antd'
import { Form, Formik } from 'formik'
import React from 'react'
import { EMAIL } from '../../../routes/routeConstants/appRoutes'
import UserService from '../../../services/AuthService/auth.service'
import InputField from '../../../shared/components/InputField'
import { emailSchema } from '../Validations'

const EmailForm = () => {
    const { user, error, loading, forgotPassword } = UserService();
    const initialValue = {
        email: "",
    }
    const onSubmit=(email:any)=>{
        forgotPassword(email)
    }
    
    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={emailSchema}
            >
                <Form className="email__form">
                    <h2 className="form-header email">Enter email</h2>
                    <p className="form__info email">Please enter your email to send OTP for reset password.</p>
                    <InputField type="email" name="email" placeholder="Email" />
                    <Button type="primary" htmlType="submit">Send OTP</Button>
                </Form>
            </Formik>
        </div>
    )
}

export default EmailForm
