import React from 'react'
import { Modal } from 'antd'
import './actionmodal.scss'

const ActionModal = (props: {
    modalVisible: any,
    closeModal: any,
    component?: any,
    modalClassName?: string,
    title?: string,
    width?:number
}) => {
    const { modalVisible, closeModal,title, component, modalClassName, width } = props
    return (
        <Modal
            className={`app-modal ${modalClassName}`}
            centered
            title={
                title&&<h1>{title}</h1>
            }
            visible={modalVisible}
            onCancel={closeModal}
            footer={false}
            width={width}
            >
            {component}
        </Modal>
    )
}

export default ActionModal
