export const ApiRoutes = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    SIGN_UP:'/user_management/user/auth/sign_up',
    SIGN_UP_VERIFY:'/user_management/user/auth/verify_email_otp',
    LOGIN: '/users/sign_in',
    FORGOT_PASSWORD: '/users/password/forgot',
    VERIFY_OTP:'/users/password/verify_otp',
    RESET_PASSWORD: '/users/password/reset',
    LOGOUT: '/user_management/users/auth/sign_up',

    LINKED_IN_LOGIN: "/users/linked_in",
    LINKED_IN_REGISTER: "/users/linked_in_registration",

    TOPICS:'/topics_management/user/topics',
    GET_PROPOSED_TOPICS:'/topics_management/user/proposed_topics',
    GET_TOPIC:'/topics_management/user/topics/:id',
    GET_PROPOSED_TOPIC:'',

    TOPIC_TESTS:'/topics_management/user/topics/:topicId/tests',
    TEST_DETAILS:'/tests_management/user/tests/:testId',
    BASIC_TEST_DETAILS:'/tests_management/user/basic_test_details/:testId',
    SUB_TEST_DETAILS:'/tests_management/user/main_tests/:testId/sub_tests/:subTestId',
    TEST_QUESTION:'/attempts_management/user/attempts/:attemptId/questions?page=:id',
    CREATE_CERTIFICATE_TEST:'/tests_management/user/certificate_hiring_tests',
    CREATE_CUSTOM_TEST:'/tests_management/user/custom_tests',
    GET_HIRING_TESTS:'/tests_management/user/hiring_tests',
    GET_DASHBOARD_DETAILS:'/tests_management/user/tests/:testId/basic_dashboard_details',
    GET_TEST_ATTEMPTS:'/attempts_management/user/tests/:testId/attempts',
    GET_CERTIFICATE_TEST_USER:'/attempts_management/user/tests/:testId/users/:userId/attempts',
    GET_CERTIFICATE_TEST_USER_ANSWERS:'/report_management/user/attempts/:attemptId/user_answers',
    GET_CERTIFICATE_TEST_ATTEMPTS:'/attempts_management/user/tests/:testId/hiring_certificate_attempted_users',
    GET_TEST_USER_ANSWERS:'/report_management/user/attempts/:attemptId/user_answers',
    EDIT_HIRINTEST:'/tests_management/user/tests/:testId',

    TOPIC_CONTRIBUTED_QUESTIONS:'/topics_management/user/topics/:topicId/questions',
    TOPIC_CONTRIBUTED_QUESTION:'/tests_management/user/topics/:topicId/questions/:questionId',
    CREATE_TEST_QUESTION:'/tests_management/user/tests/:testId/questions',
    CREATE_TOPIC_QUESTION:'/topics_management/user/topics/:topicId/questions',
    CREATE_OPTION:'/questions_management/user/options',
    UPDATE_OPTION:'/questions_management/user/options/:id',
    GET_CONTRIBUTED_QUESTIONS:'/questions_management/user/contributed_questions',
    GET_CONTRIBUTED_QUESTION:'/questions_management/user/contributed_questions/:questionId',
    GET_TEST_QUESTION:'/questions_management/user/tests/:testId/questions/:questionId',
    VALIDATE_CONTRIBUTION_CODE:'/tests_management/user/validate_contribute_code',
    GET_PARTICULAR_QUESTION:'/questions_management/user/questions/:questionId',

    CREATE_ATTEMPT:'/attempts_management/user/tests/:testId/attempts',
    CREATE_SUBTEST_ATTEMPT:'/attempts_management/user/main_tests/:testId/sub_tests/:subTestId/attempts',
    START_TEST:'/attempts_management/user/attempts/:attemptId/start',
    SUBMIT_ANSWER:'/attempts_management/user/attempts/:attemptId/questions/:questionId/next',
    SUBMIT_TEST:'/attempts_management/user/attempts/:attemptId/submit',

    GET_CERTIFICATES:'/attempts_management/user/your_certificates',
    GET_CERTIFICATE:'/user_management/user/tests/:testId/user_certificate',
    GET_ATTEMPTED_CERTIFICATE_TESTS:'/attempts_management/user/certificate_attempts',

    GET_PROFILE:"/user_management/user/profile",

    META_TOPICS:'/topics_management/user/all_topics',
    META_TESTS:'/tests_management/user/all_tests/:topicId',
    META_COUNTRIES:'/meta/countries',
    META_CERTIFICATE: '/meta/certificate',
    META_HELP_TABS:"/meta/help_tabs",
}
