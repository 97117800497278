import React, { useState, useCallback, useEffect } from 'react'
import { Field, Form, Formik, FormikValues } from 'formik'
import { useHistory } from 'react-router'
import { TestTypes } from '../../../enums/testTypes'
import SuccessModal from '../../../shared/components/SuccessModal'
import InputField from '../../../shared/components/InputField'
import { Button, Input, Upload, Select, DatePicker, Drawer, Row, Col, Radio, Checkbox } from 'antd'
import './deployhiringtestform.scss'
import PickerField from '../../../shared/components/Picker'
import SelectField from '../../../shared/components/SelectField'
import { debounce } from "lodash";
import {
    SearchOutlined
} from '@ant-design/icons'
import * as appRoutes from './../../../routes/routeConstants/appRoutes'
import TestService from '../../../services/TestService/test.service'
import Notification from './../../../shared/components/Notification'
import { NotificationTypes } from '../../../enums/notificationTypes'
import { certificateTestValidation, customTestValidation } from './testValidation'
import MetaService from '../../../services/MetaService/meta.service'
import copyToClipboard from '../../../shared/utils/copyToClipBoard'

const DeployHiringTestForm = (props: any) => {
    const { loading, error, createCertificateTest, createCustomTest, test, topicTests, getTopicTests } = TestService()
    const { getTestsMeta, getTopicsMeta, topicsMeta, testsMeta } = MetaService()
    const { testType, topicId } = props.location.state
    const module = window.location.pathname.split('/')[2]
    const history = useHistory()
    const [successModalVisible, setSuccessModalVisible] = useState(false)
    const [ceritificateSuccessModalVisible, CeritificateSuccessModalVisible] = useState(false)
    const [topicSelectionDrawerVisible, setTopicSelectionDrawerVisible] = useState(false)
    const [selectedTopic, setSelectedTopic] = useState("")
    const [selectField, setSelectField] = useState<any>()
    const [topicTest, setTopicTest] = useState({})

    const openSuccessModal = () => {
        setSuccessModalVisible(true)
    }

    const closeSuccessModal = () => {
        setSuccessModalVisible(false)
        history.goBack()
    }

    const openCertificateSuccessModal = () => {
        CeritificateSuccessModalVisible(true)
    }

    const closeCertificateSuccessModal = () => {
        CeritificateSuccessModalVisible(false)
        history.goBack()
    }

    const openTopicSelectionDrawer = () => {
        setTopicSelectionDrawerVisible(true)
    }

    const closeTopicSelectionDrawer = () => {
        setTopicSelectionDrawerVisible(false)
    }

    const linkCopyComponent = <div className="take-hiringtest__link deploy_hiringtest_wrapper-link">
        <Formik
            initialValues={{ link: `${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${test?.encryptedTestId}` }}
            onSubmit={() => { }}
        >
            <Form className="link__form">
                <Row gutter={[12, 12]}>
                    <Col span={21}>
                        <InputField type="text" name="link" placeholder="Paste link here" className="link" />
                    </Col>
                    <Col span={3}>
                        <Button type="primary" className="blue" htmlType="submit" onClick={(e) => {
                            e.stopPropagation()
                            copyToClipboard(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${test?.encryptedTestId}`);
                            // navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${1}`);
                            Notification({
                                message: "Link copied",
                                description: "",
                                type: NotificationTypes.SUCCESS,
                            });
                        }}>Copy</Button>
                    </Col>
                </Row>
            </Form>
        </Formik>
    </div>

    const customTestContinueComponent = <Button type="primary" htmlType="submit" onClick={() => {
        test && history.push(appRoutes.VIEW_HIRINGTEST.replace(':id', `${test?.id}`), {
            testId: test?.id,
            testStatus: test?.status,
            testType: "custom"
        })
    }}>Continue</Button>

    const onSubmit = (values: any) => {
        var value = topicsMeta?.topics?.filter((value, index) => value?.id === +selectedTopic)
        setSelectField(value?.[0]?.name)
        setTopicTest(values)
        closeTopicSelectionDrawer()
    }

    const deployTest = (values: any) => {
        module === "certificate" ?
            createCertificateTest({ ...values, ...topicTest }) :
            createCustomTest(values);

        if (error) {
            Notification({
                message: "Error",
                description: `Error occured while creating the test, please try again!`,
                type: NotificationTypes.ERROR,
            });
        } else {
            module === "certificate" ?
                openCertificateSuccessModal() :
                openSuccessModal()
        }
    }

    const getSearchTopics = (query: string) => {
        getTopicsMeta(query)
    }

    const handleSearch = useCallback(debounce(q => getSearchTopics(q), 1000, {}), [])

    const onChange = (e: any) => handleSearch(e?.target?.value)

    useEffect(() => {
        getTopicsMeta()
    }, [])

    useEffect(() => {
        selectedTopic &&
            getTopicTests(selectedTopic)
    }, [selectedTopic])

    useEffect(() => {
        if (topicId) {
            setSelectedTopic(topicId)
            setTopicSelectionDrawerVisible(true)
        }
    }, [])

    return (
        <div className="app-wrapper deploy_hiringtest_wrapper">
            <div className="deploy_hiringtest_wrapper-header">
                <h1>{testType === TestTypes.CERTIFICATE ? `Deploy a Certificate Test as a Hiring Test` : `Deploy a custom Hiring Test`}</h1>
            </div>
            <div className="deploy_hiringtest_wrapper-container">
                <div className="form">
                    <Formik
                        initialValues={{}}
                        onSubmit={deployTest}
                        validationSchema={module === "certificate" ? certificateTestValidation : customTestValidation}
                    >
                        {({ values, setFieldValue }) => (
                            <Form className="deploy_hiringtest_form">
                                <div className={`deploy_hiringtest_form-fields ${testType}`}>
                                    {
                                        testType === TestTypes.CERTIFICATE &&
                                        <SelectField value={selectField} name="topic" dropdownClassName="deploy-hiringtest-select" size="middle" placeholder="Select topic" onClick={openTopicSelectionDrawer} onChange={() => ({})} />
                                    }
                                    <InputField type="text" name="testName" placeholder="Test name" />
                                    {
                                        testType === TestTypes.CUSTOM &&
                                        <>
                                            {/* <InputField type="text" name="testName" placeholder="Test name" /> */}
                                            <InputField type="number" name="timePerQuestion" placeholder="Time per question (in seconds)" />
                                        </>
                                    }
                                    <PickerField name="expiryDate" placeholder="Expiry date" setFieldValue={setFieldValue} />
                                </div>
                                <div className="deploy_hiringtest_form-actions">
                                    <Button type="text" onClick={history.goBack}>Cancel</Button>
                                    {
                                        testType === TestTypes.CERTIFICATE ?
                                            <Button type="primary" htmlType="submit">Deploy Test</Button> :
                                            <Button type="primary" htmlType="submit">Create Test</Button>
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <SuccessModal width={880} successModalVisible={successModalVisible} closeSuccessModal={() => {
                        setSuccessModalVisible(false)
                        history.goBack()
                    }} successText={'Your test has been successfully created! Add questions to this test and deploy.'} component={customTestContinueComponent} />

                    <SuccessModal successModalVisible={test ? ceritificateSuccessModalVisible : false} closeSuccessModal={() => {
                        closeCertificateSuccessModal()
                    }} successText={'Your test has been successfully deployed! Use this unique URL'} modalClassName={"link-copy-modal"} component={linkCopyComponent} />
                </div>
            </div>

            <Drawer
                title={
                    <div className="deploy-hiringtest-title">
                        <h1>Select Topic</h1>
                        <Input placeholder="Search" name="search" type="text" addonAfter={<SearchOutlined />} onChange={onChange} />
                    </div>}
                placement="right"
                width="73vw"
                onClose={closeTopicSelectionDrawer}
                visible={topicSelectionDrawerVisible}
                closable={true}
                maskStyle={{
                    opacity: .78,
                    backgroundColor: "#000"
                }}
                className="filter-drawer select-topic-drawer"
            >
                <Formik
                    initialValues={{ testId: 0 }}
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="select-topic-form">
                            <div className="select-topic-content">
                                <Row gutter={[10, 10]}>
                                    <Col span={12}>
                                        <div className="topics">
                                            <Radio.Group
                                                defaultValue={topicId}
                                                options={topicsMeta?.topics?.map((value, index) => ({ label: value?.name ?? "", value: value?.id as number })) ?? []}
                                                onChange={(checkedValues) => {
                                                    // setFieldValue("topic", checkedValues.target.value)
                                                    setSelectedTopic(checkedValues.target.value)
                                                }} />
                                        </div>
                                    </Col>
                                    {
                                        topicTests &&
                                        <Col span={12}>
                                            <div className="tests">
                                                <h2>Select Test</h2>
                                                {/* CHANGED */}
                                                {/* <Checkbox.Group
                                                    options={topicTests?.tests?.map((value) => ({ label: value?.name ?? "", value: value?.id as number })) ?? []}
                                                    onChange={(checkedValues) => setFieldValue("subTestIds", checkedValues)} /> */}
                                                {
                                                    !topicTests?.tests?.length ?
                                                        <p>No tests in this topic</p> :
                                                        <Radio.Group
                                                            options={topicTests?.tests?.map((value) => ({ label: value?.name ?? "", value: value?.id as number })) ?? []}
                                                            onChange={(checkedValues) => {
                                                                setFieldValue("testId", checkedValues.target.value)
                                                            }} />
                                                }
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            <div className="select-topic-actions">
                                <Button type="text" onClick={() => closeTopicSelectionDrawer()}>Cancel</Button>
                                <Button type="primary" disabled={values?.testId == 0 ? true : false} htmlType="submit">Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Drawer>
        </div>
    )
}

export default DeployHiringTestForm
