import React, { useEffect, useState } from 'react'
import { Col, Progress, Row, Menu, Button } from 'antd'
import './testview.scss'
import {
    RightOutlined
} from '@ant-design/icons'
import { useHistory, useRouteMatch } from 'react-router'
import * as appRoutes from './../../../../routes/routeConstants/appRoutes'
import SuccessModal from '../../SuccessModal'
import TestService from '../../../../services/TestService/test.service'
import AttemptContainer from '../../../../store/container/AttemptContainer'
import { TestTypes } from '../../../../enums/testTypes'

const TestView = (props: any) => {
    const testDetails = props.location.state
    const { attempt, valid } = props
    const { loading, error, getTestQuestion, testQuestion, submitAnswer, submitTest, attemptResult } = TestService()
    const history = useHistory()
    const match = useRouteMatch()
    const [questionNumber, setQuestionNumber] = useState(0)
    const [anwserSelected, setAnwserSelected] = useState(false)
    const [successModalVisible, setSuccessModalVisible] = useState(false)
    const [selectedOption, setSelectedOption] = useState<any>()
    const testId = window.location.pathname.split('/')[2]
    const redirectUrl = window.location.origin + appRoutes.TAKE_HIRINGTEST

    // window.addEventListener("beforeunload", event => {
    //     window.history.pushState(null, "", window.location.href);
    //     event.preventDefault()
    //     event.returnValue = ""
    // })

    if(questionNumber){
        window.onbeforeunload = function () {
            window.history.pushState(null, "", window.location.href);
            return "Do you really want to leave our application?";
            //if we return nothing here (just calling return;) then there will be no pop-up question at all
            //return;
        };
    }
        
    const [timeLeft, setTimeLeft] = useState(testDetails?.timePerQuestion)

    useEffect(() => {
        if (questionNumber === testDetails?.numberOfQuestions - 1 && timeLeft === 0) {
            anwserSelected ?
                submitAnswer({ givenAnswerId: selectedOption, timeTaken: testDetails?.timePerQuestion - timeLeft }, testQuestion?.questions?.[0]?.id as number, attempt?.id) :
                submitAnswer({ status: "skipped", timeTaken: testDetails?.timePerQuestion - timeLeft }, testQuestion?.questions?.[0]?.id as number, attempt?.id);
            submitTest(attempt?.id).then(() => {
                setSuccessModalVisible(true)
            })
        }
        else {
            var timer = setTimeout(() => {
                setTimeLeft((time: any) => time - 1)
            }, 1000);
            if (timeLeft === 0) {
                submitAnswer({ status: "un_attempted" }, testQuestion?.questions?.[0]?.id as number, attempt?.id).then(() => {
                    setQuestionNumber(state => state + 1)
                })
                clearTimeout(timer)
            }
        }
        return () => {
            clearTimeout(timer)
        }
    }, [timeLeft])

    useEffect(() => {
        getTestQuestion(attempt?.id, `${questionNumber + 1}`)
        setAnwserSelected(false)
        setTimeLeft(testDetails?.timePerQuestion)
    }, [questionNumber])

    useEffect(() => {
        if (!attempt?.id) {
            window.location.replace(redirectUrl)
        }
    }, [attempt])

    useEffect(() => {
        attemptResult && setSuccessModalVisible(true)
    }, [attemptResult])

    return (
        <div className="testview__template" onContextMenu={(e) => e.preventDefault()}>
            <div className="testview__template-header">
                <div className="title">
                    {testDetails?.name}
                </div>
                <div className="timer">
                    <i className="icon-time"></i> You have {timeLeft} seconds
                </div>
            </div>
            <div className="testview__template-body">
                <Row align="stretch" justify="center">
                    <Col span={14}>
                        <div className="question">
                            <h2>{testQuestion?.questions?.[0]?.title}</h2>
                            <img src={testQuestion?.questions?.[0]?.imageUrl} alt="" />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={`answers ${testQuestion?.questions?.[0]?.optionImage?"mb-5":""}`}>
                            <h3>Select answer</h3>
                            <Menu
                                mode="inline"
                                className={`options ${testQuestion?.questions?.[0]?.optionImage ? "answers_grid" : "answers_flex"}`}
                                onSelect={(e) => {
                                    setSelectedOption(e.key)
                                    setAnwserSelected(true)
                                }}
                            >
                                {
                                    testQuestion?.questions?.[0]?.options?.map((option) => (
                                        option?.text &&
                                        <Menu.Item key={option.id}>
                                            {option.text}
                                            {
                                                option.imageUrl &&
                                                <div className="answer__image">
                                                    <img src={option.imageUrl} alt="" />
                                                </div>
                                            }
                                            {!option.imageUrl &&
                                            <div className={`${testQuestion?.questions?.[0]?.optionImage?"imagewrap":""}`}></div>}
                                        </Menu.Item>
                                    ))
                                }
                            </Menu>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="testview__template-progress">
                <Progress strokeColor={{ '0%': '#F08D47', '100%': '#EEA849' }} percent={((questionNumber + 1) / testDetails?.numberOfQuestions) * 100} showInfo={false} />
            </div>
            <div className="testview__template-footer">
                <div className="actions">
                    <div className="question-count">
                        Question {questionNumber + 1} of {testDetails?.numberOfQuestions}
                    </div>
                    <div>
                        {
                            questionNumber === testDetails?.numberOfQuestions - 1 ?
                                <Button type="primary" onClick={() => {
                                    anwserSelected ?
                                        submitAnswer({ givenAnswerId: selectedOption, timeTaken: testDetails?.timePerQuestion - timeLeft }, testQuestion?.questions?.[0]?.id as number, attempt?.id) :
                                        submitAnswer({ status: "skipped", timeTaken: testDetails?.timePerQuestion - timeLeft }, testQuestion?.questions?.[0]?.id as number, attempt?.id);
                                    submitTest(attempt?.id)
                                }}>Finish <RightOutlined /></Button> :
                                <>
                                    <Button type="text" onClick={() => {
                                        submitAnswer({ status: "skipped", timeTaken: testDetails?.timePerQuestion - timeLeft }, testQuestion?.questions?.[0]?.id as number, attempt?.id).then(() => {
                                            setQuestionNumber(state => state + 1)
                                        })
                                    }}>skip</Button>
                                    <Button type="primary" onClick={() => {
                                        anwserSelected && submitAnswer({ givenAnswerId: selectedOption, timeTaken: testDetails?.timePerQuestion - timeLeft }, testQuestion?.questions?.[0]?.id as number, attempt?.id).then(() => {
                                            setQuestionNumber(state => state + 1)
                                        })
                                    }}>Next Question <RightOutlined /></Button>
                                </>
                        }
                    </div>
                </div>
            </div>
            <SuccessModal
                successModalVisible={successModalVisible}
                failure={attemptResult?.testType === TestTypes.CERTIFICATE && attemptResult?.testStatus !== "passed"}
                closeSuccessModal={() => {
                    setSuccessModalVisible(false)
                    history.goBack()
                    history.goBack()
                    history.goBack()
                }}
                successText={attemptResult?.testType === TestTypes.CERTIFICATE ? attemptResult?.testStatus === "passed" ? 'You have completed the test successfully & earned a Certificate!' : 'Sorry, you have failed to earn a certificate' : 'You have completed the test successfully. Your result will be shared with the hiring manager.'}
                result={attemptResult?.testType === TestTypes.CERTIFICATE ? `${attemptResult?.score}/${attemptResult?.maxScore}` : ''} />

            {/* <SuccessModal
                failure={true}
                successModalVisible={successModalVisible}
                closeSuccessModal={() => {
                    setSuccessModalVisible(false)
                    history.goBack()
                    history.goBack()
                }}
                successText={'Sorry, you have failed to earn a certificate'} 
                resultText={`Your Results:4/7`}
                />

            <SuccessModal
                exception={true}
                successModalVisible={successModalVisible}
                closeSuccessModal={() => {
                    setSuccessModalVisible(false)
                    history.goBack()
                    history.goBack()
                }}
                successText={'You have reached the maximum number of attempts. You cannot take this test.'} /> */}

        </div>
    )
}

export default AttemptContainer(TestView)
