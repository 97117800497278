
import {serializable, alias, object, list, primitive} from 'serializr';

export class Topic { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

}

export class Topics{
    @serializable(alias("topics", list(object(Topic))))
    topics?: Topic[]=[];
}

export class Test { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('encrypted_test_id', primitive()))
	encryptedTestId?: number;

}

export class Tests{
    @serializable(alias("tests", list(object(Test))))
    tests?: Test[]=[];
}