import React, { useEffect, useState } from 'react'
import { Button, Row, Table } from 'antd';
import {
  LeftOutlined
} from '@ant-design/icons'
import './hiringtestreport.scss'
import { useHistory } from 'react-router'
import TestService from '../../../../services/TestService/test.service';
import ParagraphEllipsis from '../../../../shared/components/ParagraphEllipsis';
import { TestTypes } from '../../../../enums/testTypes';
import { Collapse } from 'antd';
import { useRouteMatch } from 'react-router-dom';

const { Panel } = Collapse;

const HiringTestReport = (props: any) => {
  const { loading, error, getTestUserAnswers, testUserAnswers, getCertificateTestUserAttempts, certificateTestUserAttempts } = TestService()
  const history = useHistory()
  let match = useRouteMatch();
  const [attemptId, setAttemptId] = useState<string>()
  const [reportTestId, setReportTestId] = useState<string>()
  const testId = window.location.pathname.split('/')[2]
  const userId = window.location.pathname.split('/')[3]
  const columns = props.location.state.type === TestTypes.CERTIFICATE ?
    [
      {
        title: 'QUESTION ID',
        dataIndex: 'id',
      },
      {
        title: 'TAGS',
        dataIndex: 'tags',
        // render: ((_: any, row: any) => <p className={`tag ${row?.tags}`}>{row?.tags}</p>)
        render: ((_: any, row: any) => (
          row?.tags.length>0 &&
          <Row gutter={[10, 10]}>
            {
              <p className={`tag`}>{row?.tags.toString().replaceAll(",", " ")}</p>
            }
          </Row>
        ))
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        render: ((_: any, row: any) => <p className={`status ${row?.status}`}>{row?.status}</p>)
      },
    ] :
    [
      {
        title: 'QUESTIONS',
        dataIndex: 'id',
        render: ((_: any, row: any) => <ParagraphEllipsis text={`${row?.id}. ${row?.question}`}></ParagraphEllipsis>)
      },
      {
        title: 'ANSWER',
        dataIndex: 'answer',
      },
      {
        title: 'TIME PER QUESTION',
        dataIndex: 'timeTaken',
        render: ((_: any, row: any) => <span>{row?.timeTaken}{` sec`} </span>)
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        render: ((_: any, row: any) => <p className={`status ${row?.status}`}>{row?.status}</p>)
      },
    ];

  useEffect(() => {
    // CHANGED
    // props?.location?.state?.type === TestTypes.CERTIFICATE ?
    //   getCertificateTestUserAttempts(testId, userId) :
      getTestUserAnswers(props.location.state.userId)
  }, [])

  // CHANGED
  // useEffect(() => {
  //   getTestUserAnswers(`${attemptId}`)
  // }, [attemptId])

  return (
    <div className="app-wrapper">
      <Row className="test__user-header">
        <div className="title">
          <LeftOutlined onClick={() => history.goBack()} />
          <h1>{props.location.state.email}</h1>
        </div>
        <div className="export-action">
          {/* <Button className="outlined">Export</Button> */}
        </div>
      </Row>
      {/* CHANGED */}
      {
        // props?.location?.state?.type === TestTypes.CERTIFICATE ?
        //   <>
        //     {
        //       certificateTestUserAttempts?.tests?.map((test) => (
        //         <Collapse bordered={false} onChange={(e) => setReportTestId(`${e}`)} className='mb-2' accordion={true} activeKey={`${reportTestId}`} destroyInactivePanel={true}>
        //           <Panel header={test?.name} key={`${test?.id}`}>
        //             {
        //               test?.attempts?.map((attempt, index) => (
        //                 <Collapse bordered={false} onChange={(e) => setAttemptId(`${e}`)} accordion={true} activeKey={`${attemptId}`} destroyInactivePanel={true} className='attempt-collapse mb-2'>
        //                   <Panel header={`attempt ${index + 1}`} key={`${attempt?.id}`} className='test__user-table'>
        //                     {
        //                       <Table
        //                         className='antd-table-full attempt-table'
        //                         loading={loading}
        //                         onRow={(record, index) => ({
        //                           onClick: (event) => {
        //                             // history.push(`${match.url}/${record?.id}`,record?.id)
        //                           }
        //                         })}
        //                         columns={columns}
        //                         dataSource={testUserAnswers?.userAnswers}
        //                         size="middle"
        //                         pagination={false}
        //                         scroll={{ y: 580 }} />
        //                     }
        //                   </Panel>
        //                 </Collapse>
        //               ))
        //             }
        //           </Panel>
        //         </Collapse>
        //       ))
        //     }
        //   </> :
          <Row className={`test__user-table ${props.location.state.type} antd-table-full`}>
            <Table
              loading={loading}
              onRow={(record, index) => ({
                onClick: (event) => {
                  props.location.state.type === TestTypes.CUSTOM && 
                  history.push(`${match.url}/${record?.id}`,record?.questionId)
                }
              })}
              columns={columns}
              dataSource={testUserAnswers?.userAnswers}
              size="middle"
              pagination={false}
              scroll={{ y: 580 }} />
          </Row>
      }
    </div>
  )
}

export default HiringTestReport
