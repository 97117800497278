import { Button, Row } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import * as appRoutes from './../../../../routes/routeConstants/appRoutes'
import {
    LeftOutlined
} from '@ant-design/icons'
import TestService from '../../../../services/TestService/test.service'

const StartTest = (props:any) => {
    const {loading,error,createAttempt,attempt,getTestDetails,test, startTest} = TestService()
    const history = useHistory()

    useEffect(() => {
        getTestDetails(props.location.state)
        createAttempt(props.location.state)
    }, [])

    return (
        <div className="app-wrapper test__instructions">
            <Button type="primary" onClick={()=>{
                history.push(appRoutes.TAKE_A_TEST_VIEW.replace(':testId',props.location.state),test)
            }}>Start Test</Button>
            {/* <Row className="test__view-banner test__instructions-header">
                <div className="title__container">
                    <div className="title">
                        <LeftOutlined onClick={history.goBack} />
                        <h1 onClick={history.goBack}>
                            {test?.name}
                        </h1>
                    </div>
                    <div className="info">
                        <p>{test?.noOfQuestions} Questions</p>
                    </div>
                </div>
                <div className="footer">
                    {test?.totalTime}
                </div>
            </Row>
            <div className="test__instructions-texts">
                <div className="test__instructions-texts-list">
                    <h3>Instructions</h3>
                    <ul>
                        <li>
                            This is a timed test.
                        </li>
                        <li>
                            Please make sure you are not interrupted during the test, as the timer cannot be paused once started.
                        </li>
                        <li>
                            Please ensure you have a stable internet connection. Each question has a timer.
                        </li>
                        <li>
                            The test will automatically move onto the next question if you do not answer in the allotted time.
                        </li>
                    </ul>
                </div>
                <div className="test__instructions-texts-actions">
                    <Button type="text" onClick={history.goBack}>Cancel</Button>
                    <Button type="primary" onClick={() => {
                        history.push(appRoutes.START_A_TEST_VIEW,props.location.state)
                        // history.push(appRoutes.TAKE_A_TEST_VIEW.replace(':testId',props.location.state),test)
                    }}>Continue</Button>
                </div>
            </div> */}
        </div>
    )
}

export default StartTest
