
import {serializable, alias, object, list, primitive} from 'serializr';

export class Profile { 

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('country_name', primitive()))
	countryName?: string;

	@serializable(alias('country_id', primitive()))
	countryId?: string;

	@serializable(alias('job_title', primitive()))
	jobTitle?: string;

	@serializable(alias('image', primitive()))
	image?: string;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

}

export class Country { 


	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;


}

export class Countries {
	@serializable(alias("countries", list(object(Country))))
	countries?: Country[] = [];
}

export class HelpTab { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('image', primitive()))
	image?: string;

}

export class HelpTabs{
    @serializable(alias("help_tabs", list(object(HelpTab))))
    helpTabs?: HelpTab[];
}