import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { DatePicker, Input, TimePicker } from 'antd';
import Error from "../Error";

interface PickerFieldProps {
    className?: string;
    title?: string;
    type?: string;
    name: string;
    placeholder: string;
    addonAfter?: any;
    addonBefore?: any;
    disabled?: boolean;
    onBlur?: (params: Event) => void;
    onFocus?: (params: Event) => void;
    setFieldValue?: any;
    pickType?:string;
}

const PickerField: FC<PickerFieldProps> = (props) => {
    const { name, title, setFieldValue, placeholder, pickType } = props;
    return (
        <div>
            <label htmlFor={name}>
                {title}
            </label>
            {
                pickType==="time"?
                <TimePicker onChange={(time,timeString)=>{
                    setFieldValue(name,timeString)
                }} placeholder={placeholder}/>:
                <DatePicker disabledDate={(current)=>current && current.valueOf() < Date.now()} onChange={(date, dateString) => {
                    setFieldValue(name,dateString)
                }} placeholder={placeholder} />
            }
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default PickerField;