import { serializable, alias, primitive, object } from 'serializr';

export class User {
	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('password', primitive()))
	password?: string;

	@serializable(alias('password1', primitive()))
	password1?: string;

	@serializable(alias('password2', primitive()))
	password2?: string;

	@serializable(alias('full name', primitive()))
	fullName?: string;

	@serializable(alias('profile_image', primitive()))
	profileImage?: string;

	@serializable(alias('otp', primitive()))
	otp?: string;
}

export class Image { 

	@serializable(alias('url', primitive()))
	url?: string;

}

export class ProdUser {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('provider', primitive()))
	provider?: string;

	@serializable(alias('uid', primitive()))
	uid?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('job_title', primitive()))
	jobTitle?: string;

	@serializable(alias('otp', primitive()))
	otp?: string;

	@serializable(alias('sent_at', primitive()))
	sentAt?: string;

	@serializable(alias('expires_at', primitive()))
	expiresAt?: string;

	@serializable(alias('is_active', primitive()))
	isActive?: string;

	@serializable(alias('is_deleted', primitive()))
	isDeleted?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('country_id', primitive()))
	countryId?: string;

    @serializable(alias('country', primitive()))
	country?: string;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

    @serializable(alias('profile_image', primitive()))
	profileImage?: string;

	@serializable(alias('image', object(Image)))
	image?: Image;
}

export class LinkedInAuthModel {
    @serializable(alias('authorization_code', primitive()))
    authorizationCode?: string;
}