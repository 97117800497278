import React, { useCallback, useEffect, useState } from 'react'
import { Row, Menu, Button, Tabs, Col, Table, Input } from 'antd'
import { useHistory, useRouteMatch } from 'react-router';
import { Form, Formik } from 'formik';
import InputField from '../../shared/components/InputField';
import {
    SearchOutlined
} from '@ant-design/icons'
import './topics.scss';
import TopicsList from '../../shared/components/TopicsList';
import * as appRoutes from './../../routes/routeConstants/appRoutes'
import TopicService from '../../services/TopicService/topic.service';
import MetaService from '../../services/MetaService/meta.service';
import { debounce } from "lodash";
import LocalStorage from '../../shared/localStorage';
import ProfileService from '../../services/ProfileService';

const { TabPane } = Tabs;

const Topics = () => {
    const { getProfile } = ProfileService()
    const match = useRouteMatch()
    const history = useHistory()
    const { error, loading, getTopics, topics, getProposedTopics, proposedTopics } = TopicService();
    const {topicsMeta,getTopicsMeta} = MetaService()
    const [topic, setTopic] = useState("topics");
    const testId=LocalStorage.getItem('tiger-test-id')
    const [tabKey, setTabKey] = useState("1")

    const handleTopics = (e: any) => {
        setTopic(e.key);
        (window as any).topicType = e.key
    }

    useEffect(() => {
        (window as any).topicType = "topics"
        getTopics()
        getProposedTopics()
        getTopicsMeta()
        getProfile()

        if(testId&&testId!=="certificate"){
            history.push(appRoutes.TAKE_A_TEST.replace(':testId',`${testId}`),testId)
        }
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'name',
        },
    ];

    const getSearchTopics = (query: string) => {
        // PASS SEARCH TEAT TO META
        getTopicsMeta(query)
      }
    
      const handleSearch = useCallback(debounce(q => getSearchTopics(q), 1000, {}), [])
    
      const onChange = (e: any) => handleSearch(e?.target?.value)

    return (
        <div className="topics-list app-wrapper">
            <Row className="topics-list__menubar" align="top">
                <div className="topics-menubar">
                <Tabs defaultActiveKey="1" onChange={(e) => { 
                    e=="1"?getTopics():getProposedTopics()
                 }} onTabClick={(key, event) => {
                    setTabKey(key)
                    // setRender(state => !state)
                }}>
                    <TabPane tab="Topics" key="1">
                       
                    </TabPane>
                    <TabPane tab="Your proposed topics" key="2">
                        
                    </TabPane>
                </Tabs>
                </div>
                <div className="create-topic">
                    <Button type="primary" onClick={() => history.push(appRoutes.PROPOSE_TOPIC)}>Propose new Topic</Button>
                </div>
            </Row>
            <Row className="topics-list__container" gutter={20}>
                {
                    tabKey === "1" &&
                    <>
                        <Col span={6} className="topics-list__search-bar">
                            <div className="search-box">
                                <Formik
                                    initialValues={{ search: "" }}
                                    onSubmit={() => { }}
                                >
                                    <Form className="search__form">
                                        <Input onChange={onChange} placeholder="Search" name="search" type="text" addonAfter={<SearchOutlined />} />
                                    </Form>
                                </Formik>
                                <div className="list-languages">
                                    <Table loading={loading}
                                        onRow={(record, index) => ({
                                            onClick: (event) => {
                                                history.push(`${match.url}/${record?.id}`)
                                            }
                                        })}
                                        columns={columns}
                                        dataSource={topicsMeta?.topics}
                                        size="middle"
                                        pagination={false}
                                        scroll={{ y: 580 }} />
                                </div>
                            </div>
                        </Col>
                        <Col span={18} className="topics-list__items">
                            <TopicsList topics={topics?.topics} cols={8}/>
                        </Col>
                    </>
                }
                {
                    tabKey === "2" &&
                    <>
                        <Col span={24} className="topics-list__items">
                            <TopicsList topics={proposedTopics?.topics} cols={6}/>
                        </Col>
                    </>
                }
            </Row>
        </div>
    )
}

export default Topics
