import React, { useEffect } from 'react'
import { Popover, Row, Table } from "antd";
import { useHistory } from "react-router";
import * as appRoutes from './../../routes/routeConstants/appRoutes'
import Notification from './../../shared/components/Notification';
import './managehiringtest.scss'
import ParagraphEllipsis from '../../shared/components/ParagraphEllipsis';
import TestService from '../../services/TestService/test.service';
import { NotificationTypes } from '../../enums/notificationTypes';
import { TestTypes } from '../../enums/testTypes';
import copyToClipboard from '../../shared/utils/copyToClipBoard';

const ManageHiringTest = () => {
  const { loading, error, getHiringTests, hiringTests, deleteHiringTest } = TestService()
  const history = useHistory()

  const getPopoverContent = (content: String) => {
    return <p>{content}</p>
  }

  const deleteATest = (testId: string) => {
    deleteHiringTest(testId).then(() => {
      getHiringTests()
    })
    if (error) {
      Notification({
        message: "Error",
        description: `Error deleting the test`,
        type: NotificationTypes.ERROR,
      });
    } else {
      Notification({
        message: `Test deleted successfully`,
        description: "",
        type: NotificationTypes.SUCCESS,
      });
    }
  }

  const columns = [
    {
      title: 'TEST NAME',
      dataIndex: 'name',
      render: ((_: any, row: any) => <ParagraphEllipsis text={row?.name}></ParagraphEllipsis>)
    },
    {
      title: 'TEST TYPE',
      dataIndex: 'testType',
      render: ((_: any, row: any) => <ParagraphEllipsis text={row?.testType === TestTypes.HIRING_CERTIFICATE ? "certificate" : "custom"}>{row?.testType === TestTypes.HIRING_CERTIFICATE ? "certificate" : "custom"}</ParagraphEllipsis>)
    },
    {
      title: 'NO OF ATTEMPTS',
      dataIndex: 'noOfAttempts',
    },
    {
      title: 'NO OF USERS',
      dataIndex: 'noOfUsers',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: ((_: any, row: any) =>
      (
        <>
          <p className={`status ${row?.status}`}>{row?.status?.replace('_', " ")}</p>
          <div className="">
            <>
              <Popover placement="top" content={getPopoverContent("Link copied!")} trigger="hover">
                {
                  row?.status=="active" &&
                  <i className="icon-copy-link"
                    onClick={(e) => {
                      e.stopPropagation()
                      copyToClipboard(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${row?.encryptedTestId}`);
                      // navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${row?.id}`);
                    }}
                  ></i>
                }
              </Popover>
              <Popover placement="top" content={getPopoverContent("Edit")} trigger="hover">
                <i className="icon-edit"></i>
              </Popover>
            </>
            <Popover placement="top" content={getPopoverContent("Delete")} trigger="hover">
              <i className="icon-delete" onClick={(e) => {
                e.stopPropagation()
                deleteATest(row?.id)
              }}></i>
            </Popover>
          </div>
        </>
      )),
      className: "actions"
    },
  ]

  useEffect(() => {
    getHiringTests()
  }, [])

  return (
    <div className="hiring-tests__list antd-table-full app-wrapper">
      <div className="hiring-tests__list-header">
        <div className="gutter-row">
          <h1>{hiringTests?.noOfTestsDeployed || 0}</h1>
          <p>NO. OF TESTS DEPLOYED</p>
        </div>
        <div className="gutter-row">
          <h1>{hiringTests?.activeTests || 0}</h1>
          <p>ACTIVE TESTS</p>
        </div>
        <div className="gutter-row">
          <h1>{hiringTests?.testsNotDeployed || 0}</h1>
          <p>TESTS NOT DEPLOYED</p>
        </div>
        <div className="gutter-row">
          <h1>{hiringTests?.noOfExpiredTests || 0}</h1>
          <p>NO OF EXPIRED TESTS</p>
        </div>
      </div>
      <Row className="hiring-tests__list-table">
        <Table
          loading={loading}
          onRow={(record, index) => ({
            onClick: (event) => {
              history.push({pathname: appRoutes.VIEW_HIRINGTEST.replace(":testId", `${record?.id}`), state: {
                testId: record?.id,
                encryptedTestId: record?.encryptedTestId,
                testStatus: record?.status,
                testType: record?.testType === TestTypes.HIRING_CERTIFICATE ? "certificate" : "custom"
              }, hash: "dashboard"})
            }
          })}
          // columns={columns} dataSource={module==="hirin-test"?hiringTests?.tests:certificateTests?.tests} size="middle" pagination={false} scroll={{ y: 550 }} />
          columns={columns}
          dataSource={hiringTests?.tests}
          size="middle"
          pagination={false} scroll={{ y: 480 }} />
      </Row>
    </div>
  )
}

export default ManageHiringTest
