import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import { Certificate, Certificates, Topic, Topics } from "../../models/Topic/topic.model";
import { Tests } from "../../models/Meta/meta.model";

const TopicService = () => {
    const history = useHistory();

    const [topics, setTopics] = useState<Topics>();
    const [topic, setTopic] = useState<Topic>();

    const [proposedTopics, setProposedTopics] = useState<Topics>();
    const [proposedTopic, setProposedTopic] = useState<Topic>();
    
    const [certificates, setCertificates] = useState<Certificates>();
    const [attemptedCertificates, setAttemptedCertificates] = useState<Tests>();

    const [error, setError] = useState<Error>();

    const [loading, setLoading] = useState(false);

    const getTopics = (search?:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.TOPICS,{params:{"search":search}})
            .then((response) => {
                const _topics = deserialize(Topics, response.data);
                setTopics(_topics);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getProposedTopics = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_PROPOSED_TOPICS)
            .then((response) => {
                const _topics = deserialize(Topics, response.data);
                setProposedTopics(_topics);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTopic = (topicId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TOPIC.replace(":id", topicId))
            .then((response) => {
                const _topic = deserialize(Topic, response.data["topic"]);
                setTopic(_topic);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createTopic=(topic: FormData)=>{
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.TOPICS,topic,{headers:{'Content-Type':'multipart/form-data'}})
            .then((response) => {
                // const _topic = deserialize(Topic, response.data["topic"]);
                // setTopic(_topic);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getCertificates = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_CERTIFICATES)
            .then((response) => {
                const _certificates = deserialize(Certificates, response.data);
                setCertificates(_certificates);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getAttemptedCertificateTests = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_ATTEMPTED_CERTIFICATE_TESTS)
            .then((response) => {
                const _attemptedcertificates = deserialize(Tests, response.data);
                setAttemptedCertificates(_attemptedcertificates);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        topics,
        error,
        loading,
        getTopics,
        topic,
        getTopic,
        createTopic,
        proposedTopics,
        proposedTopic,
        getProposedTopics,
        certificates,
        getCertificates,
        getAttemptedCertificateTests,
        attemptedCertificates
    };
};

export default TopicService;
