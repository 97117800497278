import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Col, Drawer, Input, Radio, Row, Table } from "antd";
import './contributequestions.scss'
import QuestionService from "../../services/QuestionService/question.service";
import { useHistory, useRouteMatch } from "react-router";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { Form, Formik, FormikValues } from "formik";
import { QuestionTypes } from "../../enums/questionTypes";
import { Typography } from 'antd';
import {
    EllipsisOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import LocalStorage from "../../shared/localStorage";
import notSortedIcon from './../../assets/icons/notsorted.svg'
import sortedIcon from './../../assets/icons/sorted.svg'
import moment from 'moment'
import ParagraphEllipsis from "../../shared/components/ParagraphEllipsis";
import ActionModal from "../../shared/components/ActionModal";
import certificateImage from './../../assets/images/certificate-test.png'
import customImage from './../../assets/images/custom-test.png'
import FilterDrawer from "../../shared/components/FilterDrawer";
import NewQuestionDrawer from "../../shared/components/NewQuestionDrawer";
import InputField from "../../shared/components/InputField";
import SuccessModal from "../../shared/components/SuccessModal";
import MetaService from "../../services/MetaService/meta.service";
import TestService from "../../services/TestService/test.service";
import {
    SearchOutlined
} from '@ant-design/icons'
import { debounce } from "lodash";

const { Paragraph, Text } = Typography;

const ContributeQuestions = (props: any) => {
    let match = useRouteMatch();
    const history = useHistory()
    const { error, loading, getContributedQuestions, questions } = QuestionService();
    const { topicsMeta, getTopicsMeta } = MetaService()
    const { test, getTestAccess } = TestService()

    const [visible, setVisible] = useState(false);

    const [contributionModalVisible, setContributionModalVisible] = useState(false)
    const [topicFilterVisible, setTopicFilterVisible] = useState(false)
    const [newQuestionDrawerVisible, setNewQuestionDrawerVisible] = useState(false)
    const [accessCodeModalVisible, setAccessCodeModalVisible] = useState(false)
    const [successModalVisible, setSuccessModalVisible] = useState(false)
    const [validAccessCodeModal, setValidAccessCodeModal] = useState(false)
    const [selectedTopic, setSelectedTopic] = useState()
    const [contributionTest, setContributionTest] = useState<any>()

    useEffect(() => {
        setTimeout(() => {
            (LocalStorage.getItem("selected-topic") || LocalStorage.getItem("selected-test")) && 
            setNewQuestionDrawerVisible(true)
        }, 1000);
    }, [loading])

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const columns = [
        {
            title: 'QUESTION ID',
            dataIndex: 'id',
            sorter: (a: any, b: any) => a?.id - b?.id,
            width: 120,
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
            onFilter: (value: any, record: any) => record?.type?.indexOf(value) === 0,
            sorter: (a: any, b: any) => a?.type?.localeCompare(b?.type),
            width: 100
        },
        {
            title: 'QUESTION TITLE',
            dataIndex: 'title',
            ellipsis: true,
            onFilter: (value: any, record: any) => record?.title?.indexOf(value) === 0,
            sorter: (a: any, b: any) => a?.title?.localeCompare(b?.title),
            width: 250
        },
        {
            title: 'TOPIC/TEST NAME',
            dataIndex: 'topicTest',
            ellipsis: true,
            onFilter: (value: any, record: any) => record?.topicTest?.indexOf(value) === 0,
            width: 200,
            sorter: (a: any, b: any) => a?.topicTest?.localeCompare(b?.topicTest)
        },
        {
            title: 'DATE',
            dataIndex: 'createdAt',
            onFilter: (value: any, record: any) => record?.createdAt?.indexOf(value) === 0,
            width: 150,
            sorter: (a: any, b: any) => moment(a?.createdAt, "YYYY/MM/DD HH:mm:ss").diff(moment(b?.createdAt, "YYYY/MM/DD HH:mm:ss"))
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: ((_: any, row: any) => <p className={`status ${row?.status}`}>{row?.status?.replace('_', " ")}</p>),
            onFilter: (value: any, record: any) => record?.status?.indexOf(value) === 0,
            width: 150,
            sorter: (a: any, b: any) => a?.status?.localeCompare(b?.status)
        },
    ];

    const getLabelandValue = (array: any) => {
        array?.map((values: any) => ({ label: values.name ?? values.email ?? "", value: values.id as number }))
    }

    const customTestContinueComponent = <Button type="primary" htmlType="submit" onClick={() => {
        // history.push(appRoutes.MANAGE_HIRINGTESTS)
        setValidAccessCodeModal(false)
        setNewQuestionDrawerVisible(true)
    }}>Continue</Button>

    const submitAccessCode = (values: FormikValues) => {
        getTestAccess(values?.code)
    }

    const getSearchTopics = (query: string) => {
        // PASS SEARCH TEAT TO META
        getTopicsMeta(query)
    }

    const handleSearch = useCallback(debounce(q => getSearchTopics(q), 1000, {}), [])

    const onChange = (e: any) => handleSearch(e?.target?.value)

    useEffect(() => {
        if (test) {
            setAccessCodeModalVisible(false);
            setValidAccessCodeModal(true);
            setContributionTest(test?.id)
        }
    }, [test])

    useEffect(() => {
        getContributedQuestions()
        getTopicsMeta()
    }, [])

    useEffect(() => {
        getContributedQuestions()
    }, [newQuestionDrawerVisible])

    return (
        <div className="questions__list app-wrapper">
            <Row className="questions__list-header">
                <h1>All Questions</h1>
                <Button type="primary" onClick={() => setContributionModalVisible(true)}>Contribute more</Button>
            </Row>
            <Row className="questions__list-table all_questions antd-table-full">
                <Table
                    onRow={(record, index) => ({
                        onClick: (event) => {
                            history.push(appRoutes.VIEW_QUESTION.replace(":id", `${record?.id}`), record?.id)
                        }
                    })}
                    columns={columns}
                    dataSource={questions?.questions}
                    size="middle"
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 560 }} />
            </Row>

            <ActionModal
                modalClassName={"contribute-question-modal"}
                width={880}
                modalVisible={contributionModalVisible}
                closeModal={() => setContributionModalVisible(false)}
                component={
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <div className="certificate-card" onClick={() => {
                                setContributionModalVisible(false)
                                setTopicFilterVisible(true)
                            }}>
                                <img src={certificateImage} alt="" />
                                <h2>Contribute to public topic</h2>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="custom-card" onClick={() => {
                                setContributionModalVisible(false)
                                setAccessCodeModalVisible(true)
                            }}>
                                <img src={customImage} alt="" />
                                <h2>Contribute to Custom test</h2>
                            </div>
                        </Col>
                    </Row>
                }
            />

            <Drawer
                title={
                    <div className="deploy-hiringtest-title topics-filter-title">
                        <h1>Select Topic</h1>
                        <Input placeholder="Search" name="search" type="text" addonAfter={<SearchOutlined />} onChange={onChange} />
                    </div>
                }
                placement="right"
                width={"34vw"}
                onClose={() => setTopicFilterVisible(false)}
                visible={topicFilterVisible}
                closable={true}
                maskStyle={{
                    opacity: .78,
                    backgroundColor: "#000"
                }}
                className="filter-drawer topics-filter"
            >
                <Formik
                    initialValues={{}}
                    onSubmit={() => {
                        if(selectedTopic){
                            setTopicFilterVisible(false)
                            setNewQuestionDrawerVisible(true)
                        }
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="select-topic-form">
                            <div className="select-topic-content">
                                <Row gutter={[10, 10]}>
                                    <Col span={24}>
                                        <div className="topics">
                                            <Radio.Group
                                                options={topicsMeta?.topics?.map((value) => ({ label: value?.name ?? "", value: value?.id as number })) ?? []}
                                                onChange={(checkedValues) => {
                                                    setSelectedTopic(checkedValues?.target?.value)
                                                    // LocalStorage.setItem("contribution-test-id",checkedValues?.target?.value)
                                                }} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="select-topic-actions">
                                <Button type="text" onClick={() => setTopicFilterVisible(false)}>Cancel</Button>
                                <Button type="primary" htmlType="submit">Apply</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Drawer>

            <NewQuestionDrawer testId={contributionTest} topicId={selectedTopic} visible={newQuestionDrawerVisible} onClose={() => setNewQuestionDrawerVisible(false)} />

            <ActionModal
                width={610}
                modalVisible={accessCodeModalVisible}
                closeModal={() => {
                    setAccessCodeModalVisible(false)
                }}
                modalClassName="contribution-link-modal access-code-modal"
                title={'Please enter the access code to contribute question to this custom test.'}
                component={
                    <Formik
                        initialValues={{ code: "" }}
                        onSubmit={(values) => {
                            submitAccessCode(values)
                        }}
                    >
                        <Form className="contribution-link-modal-form">
                            <Row gutter={[12, 12]} align="stretch" className="contribution-link">
                                <Col span={24}>
                                    <InputField type="text" name="code" placeholder="Enter access code" className="code" />
                                </Col>
                            </Row>
                            <Row>
                                <Button type="primary" htmlType="submit">Verify</Button>
                            </Row>
                        </Form>
                    </Formik>
                } />

            <SuccessModal
                modalClassName={"cutomtest-access-modal"}
                width={800}
                successModalVisible={successModalVisible}
                closeSuccessModal={() => {
                    setSuccessModalVisible(false)
                }}
                successText={'Your have successfully contributed your question.'}
                />

            <SuccessModal
                successHeader={"Your access code is correct!"}
                modalClassName={"cutomtest-access-modal"}
                width={800}
                successModalVisible={validAccessCodeModal}
                closeSuccessModal={() => {
                    setValidAccessCodeModal(false)
                }}
                successText={'Your access code is correct!'}
                subSuccessText={test?.name}
                component={customTestContinueComponent} />
        </div>
    )
}

export default ContributeQuestions;
