import { Button } from 'antd';
import React from 'react'
import { useHistory } from 'react-router';
import { HOME, TOPICS } from '../../../routes/routeConstants/appRoutes';
import LocalStorage from '../../../shared/localStorage';
import { store } from '../../../store';
import AuthContainer from '../../../store/container/AuthContainer'
import { AUTHENTICATED } from '../../../store/definitions/authConstants';

const TermsAndConditions = () => {
    const history = useHistory()
    const onAgree = () => {
        store.dispatch({
            type: AUTHENTICATED,
            payload: {
                authenticated: true,
                user: LocalStorage.getItem("tiger-user-token"),
            },
        });
        // LocalStorage.getItem("new-tiger-user")===false ? LocalStorage.setItem("new-tiger-user",false) :
        //  LocalStorage.setItem("new-tiger-user",LocalStorage.getItem("new-tiger-user"));
        history.push(TOPICS)
    }
    return (
        <div className="terms_and_service">
            <h2 className="terms_and_service-header">T&C and Privacy policy</h2>
            <div className="terms_and_service-content">
                <p>
                    Questions
                    submitted for <b> CUSTOM TESTS </b>
                    remain the property of the employer and will never be shared with other companies. Tiger Testing may inspect utilization metrics to improve the quality of the platform. Questions submitted to <b>PUBLIC
                        TOPICS</b> are the intellectual property of Tiger Testing. Users
                    understand they surrender all rights to questions submitted to the platform. The transfer of this intellectual property is perpetual and irrevocable. Users assume liability for infringing copyright laws if they publish material that is owned by someone else.
                    <b> CERTIFICATE TESTS AND QUESTIONS </b> are
                    Copyright © Tiger Testing 2022 and may not be copied, transcribed, or reproduced.
                    {/* A Terms and Conditions is not required by law, unlike a Privacy Policy, but it's highly recommended to have one as you can prevent abuses happening to your website or mobile app, and to limit your own liability as the owner of the online business. Without this kind of agreement in place, and without it being properly enforced, there's no way for you can legally limit or control how anyone can use or can't use your website or app. Issues of copyright infringement can appear if users make use of your content without your permission or issues of abuses such as someone spamming other users or posting defamatory content on your website. A Terms & Conditions agreement lets you include language to forbid such activity, and can also provide a remedy (such as accounts deletion) in the event these abuses do occur. */}
                </p>
            </div>
            <Button className="success" type="primary" onClick={onAgree}>I Agree</Button>
        </div>
    )
}

export default AuthContainer(TermsAndConditions)
