import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Menu,
  Button,
  Tabs
} from "antd"
import {
  LeftOutlined
} from '@ant-design/icons'
import "./topic.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { useHistory, withRouter, useRouteMatch } from "react-router";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import CertificateTests from "./CertificateTests";
import ContributeQuestions from "../../ContributeQuestions";
import ContributedQuestions from "./ContributedQuestions";
import TopicService from "../../../services/TopicService/topic.service";
import { Typography } from 'antd';
import {
  EllipsisOutlined,
} from "@ant-design/icons";
import ParagraphEllipsis from "../../../shared/components/ParagraphEllipsis";
import LocalStorage from "../../../shared/localStorage";

const { Paragraph, Text } = Typography;

const Topic = () => {
  let match = useRouteMatch();
  const history = useHistory()
  const { TabPane } = Tabs;
  const [ellipsis, setEllipsis] = useState(true);
    const { error, loading, getTopic, topic } = TopicService();
  let topicId = window.location.pathname.split('/')[2]
  const [activeTab, setactiveTab] = useState(LocalStorage.getItem("selected-topic")?2:1)

  useEffect(() => {
    getTopic(topicId)
  }, [])

  const handleTopics = () => { }
  const callback = (key: any) => { 
    setactiveTab(key)
  }

  return (
    <div className="app-wrapper topic">
      <div className="topic__banner">
        <div className="title__container">
          <div className="title">
            <LeftOutlined onClick={history.goBack} />
            <h1 onClick={history.goBack}>
              {topic?.name}
            </h1>
          </div>
        </div>
        <div className="footer">
        <ParagraphEllipsis expandable={true} symbol={'Read more'} text={topic?.description as string}></ParagraphEllipsis>
        {/* <Paragraph ellipsis={ellipsis ? { rows: 1, expandable: true, symbol:  } : false}>{topic?.description}</Paragraph> */}
        </div>
      </div>
      <div className="topic__menu-bar">
        <div className="topics-menubar">
          <Tabs defaultActiveKey={`${activeTab}`} onChange={callback} className="app-menubar">
            <TabPane tab="Certificate Tests" key="1">
              <CertificateTests />
            </TabPane>
            <TabPane tab="Contributed Questions" key="2">
              <ContributedQuestions />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default AuthContainer(Topic);