import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import LocalStorage from "../../shared/localStorage";
import { Attempt, Attempts, Certificate, CertificateAttempts, CertificateTests, HiringTests, Test, TestAnswer, TestAttempt, TestDashboard, TestQuestion, TopicTest, TopicTests, UserAnswers } from "../../models/Test/test.model";
import { store } from "../../store";
import { VALID } from "../../store/definitions/attemptConstants";

const TestService = () => {
    const [topicTests, setTopicTests] = useState<TopicTests>();
    const [test, setTest] = useState<TopicTest>();
    const [testQuestion, setTestQuestion] = useState<TestQuestion>();
    const [hiringTests, setHiringTests] = useState<HiringTests>();
    const [testDashboard, setTestDashboard] = useState<TestDashboard>();
    const [testAttempts, setTestAttempts] = useState<Attempts>();
    const [certificateTestAttempts, setCertificateTestAttempts] = useState<CertificateAttempts>();
    const [testUserAnswers, setTestUserAnswers] = useState<UserAnswers>();
    const [attempt, setAttempt] = useState<TestAttempt>()
    const [attemptResult, setAttemptResult] = useState<TestAttempt>()
    const [userCertificate, setuserCertificate] = useState<Certificate>()
    const [userCertificateMeta, setUserCertificateMeta] = useState<Certificate>()
    const [certificateTestUserAttempts, setCertificateTestUserAttempts] = useState<CertificateTests>()
    // const [hiringTest, setHiringTest] = useState<ViewHiringTest>();
    // const [hiringTestAttempts, setHiringTestAttempts] = useState<ViewHiringTestAttempts>();
    // const [userReports, setUserReports] = useState<UserReports>();
    // const [certificateTests, setCertificateTests] = useState<HiringTests>();

    const [error, setError] = useState<Error>();

    const [loading, setLoading] = useState(false);

    const getTopicTests = (topicId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.TOPIC_TESTS.replace(":topicId", topicId))
            .then((response) => {
                const _tests = deserialize(TopicTests, response.data);
                setTopicTests(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestDetails = (testId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.BASIC_TEST_DETAILS.replace(":testId", testId))
            .then((response) => {
                const _test = deserialize(TopicTest, response.data["test"]);
                setTest(_test);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getSubTestDetails = (testId: string, subTestId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.SUB_TEST_DETAILS.replace(":testId", testId).replace(":subTestId", subTestId))
            .then((response) => {
                const _test = deserialize(TopicTest, response.data["test"]);
                setTest(_test);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestQuestion = (attemptId: string, page: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.TEST_QUESTION.replace(":attemptId", attemptId).replace(":id", page))
            .then((response) => {
                const _testquestion = deserialize(TestQuestion, response.data);
                setTestQuestion(_testquestion);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createCertificateTest = (test: Test) => {
        setLoading(true);
        const testJSON = serialize(Test, test)
        return axiosInstance
            .post(ApiRoutes.CREATE_CERTIFICATE_TEST, testJSON)
            .then((response) => {
                const _test = deserialize(TopicTest, response.data["test"]);
                setTest(_test);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createCustomTest = (test: Test) => {
        setLoading(true);
        const testJSON = serialize(Test, test)
        return axiosInstance
            .post(ApiRoutes.CREATE_CUSTOM_TEST, testJSON)
            .then((response) => {
                const _test = deserialize(TopicTest, response.data["test"]);
                setTest(_test);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getHiringTests = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_HIRING_TESTS)
            .then((response) => {
                const _hiringtests = deserialize(HiringTests, response.data["hiring_tests"]);
                setHiringTests(_hiringtests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestDashboardDetails = (testId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_DASHBOARD_DETAILS.replace(':testId', testId))
            .then((response) => {
                const _testdashboard = deserialize(TestDashboard, response.data["test"]);
                setTestDashboard(_testdashboard);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestAttempts = (testId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TEST_ATTEMPTS.replace(':testId', testId))
            .then((response) => {
                const _testattempts = deserialize(Attempts, response.data);
                setTestAttempts(_testattempts);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCertificateTestAttempts = (testId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_CERTIFICATE_TEST_ATTEMPTS.replace(':testId', testId))
            .then((response) => {
                const _testattempts = deserialize(CertificateAttempts, response.data);
                setCertificateTestAttempts(_testattempts);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCertificateTestUserAttempts = (testId: string,userId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_CERTIFICATE_TEST_USER.replace(':testId', testId).replace(':userId', userId))
            .then((response) => {
                const _certificatetestuserattempts = deserialize(CertificateTests, response.data);
                setCertificateTestUserAttempts(_certificatetestuserattempts);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestUserAnswers = (attemptId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TEST_USER_ANSWERS.replace(':attemptId', attemptId))
            .then((response) => {
                const _useranswers = deserialize(UserAnswers, response.data);
                setTestUserAnswers(_useranswers);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editHiringTest = (test: Test, testId: string) => {
        setLoading(true);
        const testJSON = {
            "test": serialize(Test, test)
        }
        return axiosInstance
            .put(ApiRoutes.EDIT_HIRINTEST.replace(':testId', testId), testJSON)
            .then((response) => {
                const _test = deserialize(Test, response.data["test"]);
                setTest(_test);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteHiringTest = (testId: any) => {
        setLoading(true);
        return axiosInstance
            .delete(ApiRoutes.EDIT_HIRINTEST.replace(":testId", testId))
            .then((response) => {
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTestAccess = (code: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.VALIDATE_CONTRIBUTION_CODE, { params: { "code": code } })
            .then((response) => {
                const _test = deserialize(TopicTest, response.data["test"]);
                setTest(_test);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createAttempt = (testId: string) => {
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.CREATE_ATTEMPT.replace(':testId', testId))
            .then((response) => {
                const _attempt = deserialize(TestAttempt, response.data["attempt"]);
                setAttempt(_attempt);
                store.dispatch({
                    type: VALID,
                    payload: {
                        valid: true,
                        attempt: _attempt,
                    },
                });
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createSubTestAttempt = (testId: string,subTestId:string) => {
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.CREATE_SUBTEST_ATTEMPT.replace(':testId', testId).replace(':subTestId', subTestId))
            .then((response) => {
                const _attempt = deserialize(TestAttempt, response.data["attempt"]);
                setAttempt(_attempt);
                store.dispatch({
                    type: VALID,
                    payload: {
                        valid: true,
                        attempt: _attempt,
                    },
                });
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const startTest = (attemptId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.START_TEST.replace(':attemptId', attemptId))
            .then((response) => {
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submitAnswer = (answer: TestAnswer, questionId: number, attemptId: string) => {
        setLoading(true);
        const answerJson ={
            "user_answers":
                serialize(TestAnswer, answer)
        } 
        return axiosInstance
            .put(ApiRoutes.SUBMIT_ANSWER.replace(':attemptId', attemptId).replace(':questionId', `${questionId}`), answerJson)
            .then((response) => {
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submitTest = (attemptId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.SUBMIT_TEST.replace(':attemptId', attemptId))
            .then((response) => {
                const _attemptresult = deserialize(TestAttempt, response.data["attempt"]);
                setAttemptResult(_attemptresult);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCertificate = (testId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_CERTIFICATE.replace(":testId", testId),{params:{"test_id":testId}})
            .then((response) => {
                const _usercertificate = deserialize(Certificate, response.data["test"]);
                setuserCertificate(_usercertificate)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCertificateMeta = (testId:string,userId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.META_CERTIFICATE,{params:{"test_id":testId,"user_id":userId}})
            .then((response) => {
                const _usercertificatemeta = deserialize(Certificate, response.data["test"]);
                setUserCertificateMeta(_usercertificatemeta)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        topicTests,
        error,
        loading,
        getTopicTests,
        getTestDetails,
        getSubTestDetails,
        test,
        getTestQuestion,
        testQuestion,
        createCertificateTest,
        createCustomTest,
        getHiringTests,
        hiringTests,
        getTestDashboardDetails,
        testDashboard,
        getTestAttempts,
        getCertificateTestAttempts,
        getCertificateTestUserAttempts,
        certificateTestUserAttempts,
        certificateTestAttempts,
        testAttempts,
        getTestUserAnswers,
        testUserAnswers,
        editHiringTest,
        deleteHiringTest,
        getTestAccess,
        createAttempt,
        createSubTestAttempt,
        attempt,
        startTest,
        submitAnswer,
        submitTest,
        attemptResult,
        getCertificate,
        userCertificate,
        getCertificateMeta,
        userCertificateMeta
    };
};

export default TestService;
