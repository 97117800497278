type Name = "tiger-user-token"|"new-tiger-user"|"tiger-user-access-token"|"tiger-test-id"|"contribution-test-id"|"selected-topic"|"selected-test";

export default class LocalStorage {
    static getItem(name: Name) {
        const value = localStorage.getItem(name);
        if(value) {
            return JSON.parse(value);
        }
    }

    static setItem(name: Name, value: any) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    static removeItem(name: Name) {
        localStorage.removeItem(name);
    }

    static clear() {
        localStorage.clear();
    }
}
