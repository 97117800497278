import React, { useEffect, useState } from "react";
import { Menu, Popover, Button, Modal, Row, Col } from 'antd';
import "./navbar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import LocalStorage from "../../localStorage";
import { useHistory, withRouter } from "react-router";
import * as appRoutes from "../../../routes/routeConstants/appRoutes"
import logo from './../../../assets/images/tiger testing-04.svg'
import {
  EllipsisOutlined,
} from "@ant-design/icons";
import TutorialPopover from "../TutorialPopover";
import AuthService from "../../../services/AuthService/auth.service";
import ProfileService from "../../../services/ProfileService";
import MetaService from "../../../services/MetaService/meta.service";


const Navbar = (props: any) => {
  const { logOut, error, loading } = AuthService()
  const { getProfile, profile } = ProfileService()
  const { getHelpTabs, helpTabs } = MetaService()
  const history = useHistory()
  const { pathname } = history.location;
  const { authenticated, user } = props;
  const [visiblePopover, setVisiblePopover] = useState([false, false, false, false, false])
  const handleLogout = () => {
    logOut().then(() => {
      Notification({ message: "Logout", description: "user loggedout successfully", type: NotificationTypes.SUCCESS })
      LocalStorage.removeItem("tiger-user-token")
      LocalStorage.removeItem("new-tiger-user")
      LocalStorage.removeItem("tiger-user-access-token")
      return props.setUnAuthenticated();
    })
  };

  useEffect(() => {
    authenticated &&
      getProfile()

    getHelpTabs().then(() => {
      setVisiblePopover([true, false, false, false, false])
    })
  }, [])

  const handleClick = (e: any) => {
    history.push(e.key);
  };
  const popoverContent = <ul className="popover-list">
    <li onClick={(e) => {
      e.stopPropagation();
      history.push(appRoutes.PROFILE)
    }}><i className="icon-my-profile"></i>My Profile</li>
    <li onClick={(e) => {
      e.stopPropagation()
      handleLogout()
    }}><i className="icon-logout"></i> Logout</li>
  </ul>

  const tutorialPopoverTitle = <div className="image">
    <i className="icon-placeholder"></i>
  </div>

  const tutorialTopicContent = <>
    <h2>{helpTabs?.helpTabs && helpTabs?.helpTabs[0].title}</h2>
    <p>
      {helpTabs?.helpTabs && helpTabs?.helpTabs[0].description}
    </p>
  </>

  const tutorialHiringContent = <>
    <h2>{helpTabs?.helpTabs && helpTabs?.helpTabs[1].title}</h2>
    <p>
      {helpTabs?.helpTabs && helpTabs?.helpTabs[1].description}
    </p>
  </>

  const tutorialCertificateContent = <>
    <h2>{helpTabs?.helpTabs && helpTabs?.helpTabs[2].title}</h2>
    <p>
      {helpTabs?.helpTabs && helpTabs?.helpTabs[2].description}
    </p>
  </>
  const tutorialQuestionContent = <>
    <h2>{helpTabs?.helpTabs && helpTabs?.helpTabs[3].title}</h2>
    <p>
      {helpTabs?.helpTabs && helpTabs?.helpTabs[3].description}
    </p>
  </>

  return (authenticated && (window.location.pathname.split('/')[1] !== "auth" && window.location.pathname.split('/')[1] !== "test")) ? (
    <div className="app-sidebar">
      <div className="app-sidebar__brand-wrapper">
        <img
          src={logo}
          className="app-sidebar__brand-logo"
          alt="Brand Logo"
          onClick={() => window.location.replace(window.location.origin)}
        />
      </div>
      <Menu
        onClick={handleClick}
        selectedKeys={[`/${pathname.split('/')[1]}`]}
        mode="inline"
        className="app-sidebar__container"
      >
        <Menu.Item key={appRoutes.TOPICS}icon={
          <i className="icon-topics" />}>
          <TutorialPopover id={0} setVisiblePopover={setVisiblePopover} visible={LocalStorage.getItem("new-tiger-user") == true ? visiblePopover[0] : false} placement="right" inner="Topics" tutorialPopoverContent={tutorialTopicContent} tutorialPopoverTitle={tutorialPopoverTitle} />
        </Menu.Item>
        <Menu.Item key={appRoutes.TAKE_HIRINGTEST} icon={<i className="icon-take-a-hiring-test" />}>
          <div className="app-sidebar__menu-title">Take a Hiring Test</div>
        </Menu.Item>
        <Menu.Item key={appRoutes.DEPLOY_HIRINGTEST}icon={
        <i className="icon-deploy-a-hiring-tets" />}>
          <TutorialPopover id={1} setVisiblePopover={setVisiblePopover} visible={LocalStorage.getItem("new-tiger-user") == true ? visiblePopover[1] : false} placement="right" inner="Deploy a Hiring Test" tutorialPopoverContent={tutorialHiringContent} tutorialPopoverTitle={tutorialPopoverTitle} />
        </Menu.Item>
        <Menu.Item key={appRoutes.MANAGE_HIRINGTESTS}icon={
        <i className="icon-manage-hiring-tests" />}>
          <div className="app-sidebar__menu-title">Manage Hiring Tests</div>
        </Menu.Item>
        <Menu.Item key={appRoutes.EARN_CERTIFICATE}icon={
          <i className="icon-earn-a-certificate" />}>
          <TutorialPopover id={2} setVisiblePopover={setVisiblePopover} visible={LocalStorage.getItem("new-tiger-user") == true ? visiblePopover[2] : false} placement="right" inner="Earn a Certificate" tutorialPopoverContent={tutorialCertificateContent} tutorialPopoverTitle={tutorialPopoverTitle} />
        </Menu.Item>
        <Menu.Item key={appRoutes.CONTRIBUTE_QUESTIONS}icon={
          <i className="icon-contribute-questions" />}>
          <TutorialPopover id={3} setVisiblePopover={setVisiblePopover} visible={LocalStorage.getItem("new-tiger-user") == true ? visiblePopover[3] : false} placement="right" inner="Contribute Questions" tutorialPopoverContent={tutorialQuestionContent} tutorialPopoverTitle={tutorialPopoverTitle} />
        </Menu.Item>

        <Menu.Item id="profile-link" key={appRoutes.PROFILE}icon={
          
            user?.imageUrl || profile?.imageUrl ?
            <img src={user?.imageUrl || profile?.imageUrl} alt="" /> :
              <i className="icon-my-profile" />
          }>
          <div className="app-sidebar__menu-title">{user?.fullName || "New User"}</div>
          <div id="user-badge" className="app-sidebar__menu-title">{user?.email}</div>
          <Popover placement="rightTop" content={popoverContent} trigger="click">
            <Button type="text" className="popover-btn" onClick={(e) => e.stopPropagation()}><EllipsisOutlined /></Button>
          </Popover>
        </Menu.Item>
      </Menu>

      <Modal
        title={<h2>Quick Navigation to:</h2>}
        visible={LocalStorage.getItem("new-tiger-user") == true ? visiblePopover[4] : false}
        onCancel={() => {
          setVisiblePopover((visiblePopover: any) => {
            let newState = visiblePopover.map((state: any) => false);
            return newState
          })
          LocalStorage.setItem("new-tiger-user", false)
        }}
        className="quick_navigation-modal app-modal"
        footer={false}
        centered={true}
        width={1180}
      >
        <Row gutter={[12, 12]}>
          <Col span={6} onClick={() => {
            history.push(appRoutes.PROPOSE_TOPIC)
            LocalStorage.setItem("new-tiger-user", false)
          }}>
            <div className="quick_navigator" id="quick_navigator">
              <h3>
                Propose new Topic
              </h3>
            </div>
          </Col>
          <Col span={6} onClick={() => {
            history.push(appRoutes.DEPLOY_HIRINGTEST)
            LocalStorage.setItem("new-tiger-user", false)
          }}>
            <div className="quick_navigator" id="quick_navigator">
              <h3>
                Deploy a Hiring Test
              </h3>
            </div>
          </Col>
          <Col span={6} onClick={() => {
            history.push(appRoutes.EARN_CERTIFICATE)
            LocalStorage.setItem("new-tiger-user", false)
          }}>
            <div className="quick_navigator" id="quick_navigator">
              <h3>
                Earn a Certificate
              </h3>
            </div>
          </Col>
          <Col span={6} onClick={() => {
            history.push(appRoutes.CONTRIBUTE_QUESTIONS)
            LocalStorage.setItem("new-tiger-user", false)
          }}>
            <div className="quick_navigator" id="quick_navigator">
              <h3>
                Contribute Questions
              </h3>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>

  ) : null;
}

export default withRouter(AuthContainer(Navbar));