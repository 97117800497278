import React from "react";
import { Tabs } from "antd";
import { useHistory, withRouter } from "react-router-dom";
import AuthForm from "../../../shared/components/AuthForm";

const LoginForm = () => {
    const history = useHistory()
    const { TabPane } = Tabs;

    const handleTabChange = (e: string) => {
        history.push(e)
    }

    return (
        <div>
            <Tabs className="antd-whole-tab" defaultActiveKey={window.location.pathname} onChange={handleTabChange}>
                <TabPane tab="Sign in" key="/auth/login">
                    <AuthForm/>
                </TabPane>
                <TabPane tab="Sign up" key="/auth/register">
                    <AuthForm/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default withRouter(LoginForm);