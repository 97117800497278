import { serializable, alias, object, list, primitive } from 'serializr';

export class SubTest { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('created_by', primitive()))
	createdBy?: string;

	@serializable(alias('topic_name', primitive()))
	topicName?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('difficulty_level', primitive()))
	difficultyLevel?: string;

	@serializable(alias('no_of_questions', primitive()))
	noOfQuestions?: number;

	@serializable(alias('number_of_questions', primitive()))
	numberOfQuestions?: number;


	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('total_time', primitive()))
	totalTime?: string;

	@serializable(alias('attempts_remaining', primitive()))
	attemptsRemaining?: number;

	@serializable(alias('test_status', primitive()))
	testStatus?: string;

}

export class TopicTest {

	@serializable(alias('encrypted_test_id', primitive()))
	encryptedTestId?: number;
	
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('topic_name', primitive()))
	topicName?: string;

	@serializable(alias('max_no_of_attempts', primitive()))
	maxNoOfAttempts?: number;

	@serializable(alias('min_pass_percent', primitive()))
	minPassPercent?: number;

	@serializable(alias('difficulty_level', primitive()))
	difficultyLevel?: string;

	@serializable(alias('number_of_questions', primitive()))
	numberOfQuestions?: number;

	@serializable(alias('no_of_questions', primitive()))
	noOfQuestions?: number;

	@serializable(alias('no_of_tests', primitive()))
	noOfTests?: number;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('total_time', primitive()))
	totalTime?: string;

	@serializable(alias('test_status', primitive()))
	testStatus?: string;

	@serializable(alias('no_of_attempts', primitive()))
	noOfAttempts?: number;

	@serializable(alias('attempts_remaining', primitive()))
	attemptsRemaining?: number;

	@serializable(alias('sub_tests', list(object(SubTest))))
	subTests?: SubTest[] = [];
	
}

export class TopicTests {
	@serializable(alias("tests", list(object(TopicTest))))
	tests?: TopicTest[] = [];
}

export class Meta { 

	@serializable(alias('current_page', primitive()))
	currentPage?: number;

	@serializable(alias('items_per_page', primitive()))
	itemsPerPage?: number;

	@serializable(alias('next_page', primitive()))
	nextPage?: number;

	@serializable(alias('prev_page', primitive()))
	prevPage?: string;

	@serializable(alias('total_count', primitive()))
	totalCount?: number;

	@serializable(alias('total_pages', primitive()))
	totalPages?: number;

}

export class Option { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('text', primitive()))
	text?: string;

	@serializable(alias('image', primitive()))
	image?: string;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

}

export class Questions { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('test_name', primitive()))
	testName?: string;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('image', primitive()))
	image?: string;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

	@serializable(alias('option_image', primitive()))
	optionImage?: string;

	@serializable(alias('options', list(object(Option))))
	options?: Option[] = [];

}

export class TestQuestion {
	@serializable(alias("meta", object(Meta)))
	meta?: Meta;

	@serializable(alias("questions", list(object(Questions))))
	questions?: Questions[]=[];
}

export class Test { 

	@serializable(alias('encrypted_test_id', primitive()))
	encryptedTestId?: number;

	@serializable(alias('test_id', primitive()))
	testId?: number;

	@serializable(alias('expiry_date', primitive()))
	expiryDate?: string;

	@serializable(alias('test_name', primitive()))
	testName?: string;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('type', primitive()))
	type?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('no_of_attempts', primitive()))
	noOfAttempts?: number;

	@serializable(alias('no_of_questions', primitive()))
	noOfQuestions?: number;

	@serializable(alias('number_of_questions', primitive()))
	numberOfQuestions?: number;

	@serializable(alias('all_questions', primitive()))
	allQuestions?: boolean;

	@serializable(alias('no_of_users', primitive()))
	noOfUsers?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('topic', primitive()))
	topic?: string;

	@serializable(alias('tests', list(primitive())))
	tests?:[]=[];

	@serializable(alias('sub_test_ids', list(primitive())))
	subTestIds?:[]=[];

}

export class HiringTests { 

	@serializable(alias('no_of_tests_deployed', primitive()))
	noOfTestsDeployed?: number;

	@serializable(alias('no_of_active_tests', primitive()))
	noOfActiveTests?: number;

	@serializable(alias('active_tests', primitive()))
	activeTests?: number;

	@serializable(alias('no_of_tests_not_deployed', primitive()))
	noOfTestsNotDeployed?: number;

	@serializable(alias('tests_not_deployed', primitive()))
	testsNotDeployed?: number;

	@serializable(alias('no_of_expired_tests', primitive()))
	noOfExpiredTests?: number;

	@serializable(alias('tests', list(object(Test))))
	tests?: Test[] = [];

}

export class TestDashboard { 

	@serializable(alias('encrypted_test_id', primitive()))
	encryptedTestId?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('expiry_date', primitive()))
	expiryDate?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('no_of_attempts', primitive()))
	noOfAttempts?: number;

	@serializable(alias('no_of_tests_completed', primitive()))
	noOfTestsCompleted?: number;

	@serializable(alias('users_score_fifty_percent_higher', primitive()))
	usersScoreFiftyPercentHigher?: number;

	@serializable(alias('users_score_fifty_percent_lower', primitive()))
	usersScoreFiftyPercentLower?: number;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('contribution_code', primitive()))
	contributionCode?: string;

	@serializable(alias('access_code', primitive()))
	accessCode?: string;

}

export class Attempt { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('user_id', primitive()))
	userId?: number;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('starts_at', primitive()))
	startsAt?: string;

	@serializable(alias('score', primitive()))
	score?: number;

	@serializable(alias('max_score', primitive()))
	maxScore?: number;

	@serializable(alias('attempt_status', primitive()))
	attemptStatus?: string;

	@serializable(alias('test_status', primitive()))
	testStatus?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

}

export class Attempts {
	@serializable(alias("attempts", list(object(Attempt))))
	attempts?: Attempt[] = [];
}

export class CertificateAttempts {
	@serializable(alias("users", list(object(Attempt))))
	users?: Attempt[] = [];
}

export class Tags { 
	@serializable(alias('current_page', primitive()))
	currentPage?: number;

	@serializable(alias('items_per_page', primitive()))
	itemsPerPage?: number;

	@serializable(alias('next_page', primitive()))
	nextPage?: number;

	@serializable(alias('prev_page', primitive()))
	prevPage?: string;

	@serializable(alias('total_count', primitive()))
	totalCount?: number;

	@serializable(alias('total_pages', primitive()))
	totalPages?: number;
}

export class UserAnswer { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('user_id', primitive()))
	userId?: number;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('answer', primitive()))
	answer?: string;

	@serializable(alias('time_taken', primitive()))
	timeTaken?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('question_id', primitive()))
	questionId?: number;

	@serializable(alias('question', primitive()))
	question?: string;

	@serializable(alias('tags', list(primitive())))
	tags=[];

}

export class UserAnswers {
	@serializable(alias("user_answers", list(object(UserAnswer))))
	userAnswers?: UserAnswer[] = [];
}

export class TestAttempt { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('user_id', primitive()))
	userId?: number;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('starts_at', primitive()))
	startsAt?: string;

	@serializable(alias('score', primitive()))
	score?: number;

	@serializable(alias('max_score', primitive()))
	maxScore?: number;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('test_status', primitive()))
	testStatus?: string;

}

export class TestAnswer { 

	@serializable(alias('given_answer_id', primitive()))
	givenAnswerId?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('time_taken', primitive()))
	timeTaken?: number;

}

export class Certificate { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('user_name', primitive()))
	userName?: string;

	@serializable(alias('passed_at', primitive()))
	passedAt?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('test_created_by', primitive()))
	testCreatedBy?: string;

}

export class CertificateTest { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('attempts', list(object(Attempt))))
	attempts?: Attempt[] = [];

}

export class CertificateTests {
	@serializable(alias("tests", list(object(CertificateTest))))
	tests?: CertificateTest[] = [];
}