import React, { useCallback, useEffect, useState } from 'react'
import { Button, Input, Table } from 'antd'
import {
    SearchOutlined,
    CheckCircleTwoTone
} from '@ant-design/icons'
import questionImage from './../../../../assets/images/question.png'
import { debounce } from "lodash";
import * as appRoutes from './../../../../routes/routeConstants/appRoutes'
import { useHistory, useRouteMatch } from 'react-router';
import './contributedquestions.scss'
import QuestionService from '../../../../services/QuestionService/question.service';
import ParagraphEllipsis from './../../../../shared/components/ParagraphEllipsis'
import LocalStorage from '../../../../shared/localStorage';

const ContributedQuestions = () => {
    const { loading, error, getTopicQuestions, questions, getTopicQuestion, question, getParticularQuestion } = QuestionService()
    const history = useHistory()
    let match = useRouteMatch();
    const topicId = window.location.pathname.split('/')[2]

    const columns = [
        {
            title: '',
            dataIndex: 'id',
            render: ((_: any, row: any) => <p className={`${question?.id == row?.id ? "bold" : ""}`}>{row?.id}.{" "}</p>)
        },
        {
            title: '',
            dataIndex: 'title',
            render: ((_: any, row: any) => <ParagraphEllipsis className={`${question?.id === row?.id ? "bold" : ""}`} text={row?.title}></ParagraphEllipsis>)
            // render: ((_: any, row: any) => <Paragraph className={`${"question?.id" == row?.id ? "bold" : ""}`} ellipsis={ellipsis ? { rows: 1, expandable: false, symbol: 'more' } : false}>{row?.title}</Paragraph>)
        },
    ];

    const getSearchQuestions = (query: string) => {
        getTopicQuestions(topicId, undefined, query)
    }

    const handleSearch = useCallback(debounce(q => getSearchQuestions(q), 1000, {}), [])

    const onChange = (e: any) => handleSearch(e?.target?.value)

    const optionImage = (element: any) => element?.imageUrl !== null
    const isGrid = question?.options?.some(optionImage)

    useEffect(() => {
        getTopicQuestions(topicId)
    }, [])

    useEffect(() => {
        if (questions?.questions?.length) {
            getParticularQuestion(`${questions?.questions?.[0].id}`)
        }
    }, [questions])

    useEffect(() => {
        LocalStorage.getItem("selected-topic") && history.push(`${match.url}/contribute-question`, { topicId })
     return ()=>{
        LocalStorage.removeItem("selected-topic")
     }
    }, [])
    

    return (
        <div className="contributed-questions">
            <Button type="primary" onClick={() => history.push(`${match.url}/contribute-question`, { topicId })}>Contribute Question</Button>
            <div className="questions__list">
                <div className="search-box">
                    <Input placeholder="Search" name="search" type="text" onChange={onChange} addonAfter={<SearchOutlined />} />
                    <Table
                        loading={loading}
                        onRow={(record, index) => ({
                            onClick: (event) => getParticularQuestion(`${record?.id}`)
                        })}
                        columns={columns}
                        dataSource={questions?.questions}
                        size="middle"
                        pagination={false}
                        scroll={{ y: 440 }} />
                </div>
            </div>
            <div className="question__view">
                {
                    question ?
                        <>
                            <div className="question__view-question">
                                <p style={{ marginBottom: "15px" }}>Question</p>
                                <p className="question-text">{question?.title}</p>
                                {
                                    question?.imageUrl &&
                                    <img src={question?.imageUrl} alt="" />
                                }
                            </div>
                            <div className="question__view-answer">
                                <p>Answers</p>
                                <div className={isGrid?"answers_grid":""}>
                                    {
                                        question?.options?.map((answer) => (
                                            // answer?.imageUrl ?
                                                <div className="answer_item">
                                                    <h3>{answer?.text}</h3>
                                                    {
                                                        answer?.imageUrl &&
                                                        <img src={answer?.imageUrl} alt="" />
                                                    }
                                                </div>
                                                // :
                                                // <h3>{answer?.text}</h3>
                                        ))
                                    }
                                </div>
                                <p style={{ marginTop: "15px" }}>Correct answer</p>
                                {
                                    <>
                                        <h3>
                                            <CheckCircleTwoTone twoToneColor="#40CE2A" />
                                            <span>{question?.correctAnswer?.text}</span>
                                        </h3>
                                        <img className="correct_answer_image" src={question?.correctAnswer?.imageUrl} alt="" />
                                    </>
                                }
                            </div>
                        </> :
                        null
                }
            </div>
        </div>
    )
}

export default ContributedQuestions
