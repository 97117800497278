import { Popover } from "antd";
import React, { FC } from "react";
import './tutorialpopover.scss'

interface PopoverProps {
    placement: string;
    tutorialPopoverTitle: any;
    tutorialPopoverContent: any;
    inner: string;
    onBlur?: (params: Event) => void;
    onFocus?: (params: Event) => void;
    setVisiblePopover: (params: any) => void;
    visible: boolean;
    id: number;
}

const TutorialPopover: FC<PopoverProps> = (props) => {
    const { tutorialPopoverTitle, tutorialPopoverContent, inner, visible, setVisiblePopover } = props;
    return (
        <>
            <Popover
                placement="right"
                title={tutorialPopoverTitle}
                content={tutorialPopoverContent}
                visible={visible}
                onVisibleChange={(e) => {
                    setVisiblePopover((visiblePopover: any) => {
                        let newState = visiblePopover.map((state: any) => false);
                        newState[props.id + 1] = true
                        return newState
                    })
                }}
            >
                <div className="app-sidebar__menu-title">{inner}</div>
            </Popover>
            {
                visible&&
                <div id="tutorial-mask"></div>
            }
        </>
    )
}

export default TutorialPopover;