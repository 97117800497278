import React from 'react'
import { Button, Col, Row } from 'antd'
import hiringTestImage from './../../assets/images/hiring-test.png'
import './takehiringtest.scss'
import { Form, Formik, FormikValues } from 'formik'
import InputField from '../../shared/components/InputField'
import * as appRoutes from './../../routes/routeConstants/appRoutes'
import { useHistory } from 'react-router'

const TakeHiringTest = () => {
    const history=useHistory()
    const onSubmit = (values:FormikValues) => {
        values.link.split('/')[5] &&
        history.push(appRoutes.TAKE_A_TEST.replace(':testId',values.link.split('/')[5]),values.link.split('/')[5])
     }
    return (
        <div className="app-wrapper">
            <div className="take-hiringtest__wrapper">
                <div className="take-hiringtest__image">
                    <img src={hiringTestImage} alt="" />
                </div>
                <div className="take-hiringtest__link">
                    <h2>Have a link for a corporate hiring test?</h2>
                    <Formik
                        initialValues={{link:""}}
                        onSubmit={onSubmit}
                    >
                        <Form className="link__form">
                            <Row gutter={[12,12]}>
                                <Col span={20}>
                                    <InputField type="text" name="link" placeholder="Paste link here" className="link" />
                                </Col>
                                <Col span={4}>
                                    <Button type="primary" className="blue" htmlType="submit">Take test</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default TakeHiringTest
