import * as Yup from "yup";

export const questionValidation = Yup.object().shape({
    correct_answer_id: Yup.string()
        .required('Correct answer is required!'),
    difficulty_level: Yup.string()
        .required('Difficulty level is required!'),
    optionsArray: Yup.array()
        .of(
            Yup.object({
                text: Yup.string(),
                imageUrl: Yup.string().nullable(),
              })
              .test(
                'either-key-one-or-key-two',
                'Either option text or image is required!',
                (obj) => Boolean(obj?.text?.length) || Boolean(obj?.imageUrl?.length)
              )
        )
}).test({
    test: function (value) {
        const { image, title } = value;
        return !(!image && !title) || this.createError({
            message: "Either question text or image is required!",
            path: "title", 
        });
    }
})