import { Button, Row } from 'antd'
import React, { useEffect } from 'react'
import {
    CheckCircleTwoTone,
    SearchOutlined,
    LeftOutlined
} from '@ant-design/icons'
import { useHistory, useRouteMatch } from 'react-router'
import questionImage from './../../../assets/images/question.png'
import './proposedtopic.scss'
import TopicService from '../../../services/TopicService/topic.service'
import { NotificationTypes } from '../../../enums/notificationTypes'
import Notification from "../../../shared/components/Notification";

const ProposedTopic = () => {
    const { topic, getTopic, error, loading } = TopicService();
    const history = useHistory()
    let match = useRouteMatch();
    let topicId = window.location.pathname.split('/')[3]

    const editStatus = (status: string) => {
        // editTopic({status:status},topicId)
        // if(error){
        //     Notification({
        //         message: "Status",
        //         description: "Error updating status",
        //         type: NotificationTypes.ERROR,
        //     });     
        // }else{
        //     Notification({
        //         message: "Status",
        //         description: "Status updated successfully",
        //         type: NotificationTypes.SUCCESS,
        //     }); 
        // }
        history.goBack()
    }

    useEffect(() => {
        getTopic(topicId)
    }, [])

    return (
        <div className="app-wrapper proposed__topic">
            <div className="proposed__topic-header">
                <div className="title">
                    <div className="">
                        <LeftOutlined onClick={history.goBack} />
                        <h1 onClick={history.goBack}>
                            {topic?.name}
                        </h1>
                    </div>
                    <div className="">
                        <Button type="primary" className={`${topic?.status==="rejected"?"danger":topic?.status==="pending_approval"?"blue":"success"}`} onClick={() => { }}>{topic?.status?.replace('_',' ')}</Button>
                    </div>
                </div>
            </div>
            <div className="proposed__topic-content">
                <p>
                    {
                        topic?.imageUrl &&
                        <img src={topic?.imageUrl} style={{ height: "320px", width: "480px" }} alt="" />
                    }
                    {topic?.description}
                </p>
            </div>
        </div>
    )
}

export default ProposedTopic
