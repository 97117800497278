import React from 'react'
import './DeployHiringTestForm'
import './deployhiringtest.scss'
import certificateBanner from './../../assets/images/certificate-test.png'
import customBanner from './../../assets/images/custom-test.png'
import * as appRoutes from './../../routes/routeConstants/appRoutes'
import { useHistory } from 'react-router'
import { TestTypes } from '../../enums/testTypes'

const DeployHiringTest = () => {
    const history=useHistory()
    
    return (
        <div className="app-wrapper deploy_hiringtest">
            <div className="deploy_hiringtest-header">
                <h1>Deploy a Hiring Test</h1>
            </div>
            <div className="deploy_hiringtest-body">
                <div className="deploy_hiringtest-body-banner" onClick={()=>history.push(appRoutes.DEPLOY_CERTIFICATE_HIRINGTEST,{testType:TestTypes.CERTIFICATE})}> 
                    <img src={certificateBanner} alt="" />
                    <h2>Deploy a Certificate test as a Hiring Test</h2>
                    <ul>
                        <li>Use Certificate Tests as a Hiring Test! Select the Test from the list of publicly available Topics.</li>
                        <li>Test questions will be randomly selected from the pool of hundreds or thousands of possible test questions. It's impossible for candidates to study for the test - They'll never get the same test questions even if they retake the same test.</li>
                        <li>You will not be able to modify the number of questions or the time limit.</li>
                        <li>Results are available on a candidate-by-candidate basis, but not on a question-by-question basis.</li>
                        <li>You can deploy the Hiring Test using a Unique URL.</li>
                    </ul>
                </div>
                <div className="deploy_hiringtest-body-banner" onClick={()=>history.push(appRoutes.DEPLOY_CUSTOM_HIRINGTEST,{testType:TestTypes.CUSTOM})}>
                    <img src={customBanner} alt="" />
                    <h2>Deploy a Custom Hiring Test</h2>
                    <ul>
                        <li>Use your own questions!</li>
                        <li>You can also ask collaborators or team members to contribute questions to the hiring test (the mini outsourcing feature). You will be able to approve all questions before they go into the hiring test.</li>
                        <li>Customize the timer per question.</li>
                        <li>You can deploy the Hiring Test using a Unique URL.</li>
                        <li>Minimum 10 questions</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default DeployHiringTest
