import { Button } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react'
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router';
import UserService from '../../../services/AuthService/auth.service';
import { otpSchema } from '../Validations';

const VerifyForm = (props:any) => {
    const {email,module}=props.location.state
    const { user, error, loading, forgotPasswordOTP, signUpVerify } = UserService();
    const history = useHistory()
    const initialValue = {
        otp: "",
    }
    const onSubmit = (otp:any) => {
        module==="reset"?
        forgotPasswordOTP({...otp,email}):
        signUpVerify({...otp,email})
     }
    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={otpSchema}
            >
                {({ setFieldValue, values }) => {
                    return (

                        <Form className="verify__form">
                            <h2 className="form-header verify">Email verification</h2>
                            <p className="form__info verify">Please enter the 4-digit verification code sent to your email.</p>
                            <OtpInput
                                onChange={(otp: any) => setFieldValue("otp", otp)}
                                value={values.otp}
                                numInputs={4}
                                separator={<span></span>}
                                containerStyle={"otp-container"}
                                inputStyle={"otp-input"}
                            />
                            <Button type="primary" htmlType="submit">verify</Button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default VerifyForm
