import React, { FC } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import { RouterProps } from "../shared/types/route.type";
import Navbar from "../shared/components/Navbar";
import Topics from "../views/Topics";
import TakeHiringTest from "../views/TakeHiringTest";
import DeployHiringTest from "../views/DeployHiringTest";
import ManageHiringTest from "../views/ManageHiringTest";
import EarnCerificate from "../views/EarnCertificate";
import ContributeQuestions from "../views/ContributeQuestions";
import Profile from "../views/Profile";
import Topic from "../views/Topics/Topic";
import ProposedTopic from "../views/Topics/ProposedTopic";
import ProposeTopic from "../views/Topics/ProposeTopic";
import NewQuestionView from "../shared/components/NewQuestionView";
import TakeATest from "../shared/components/TakeATest";
import TestView from "../shared/components/TakeATest/TestView";
import DeployHiringTestForm from "../views/DeployHiringTest/DeployHiringTestForm";
import ViewHiringTest from "../views/ManageHiringTest/ViewHiringTest";
import HiringTestReport from "../views/ManageHiringTest/ViewHiringTest/HiringTestReport";
import Certificate from "../views/EarnCertificate/Certificate";
import Question from "../shared/components/Question";
import StartTest from "../shared/components/TakeATest/StartTest";
import TakeATestCertificate from "../shared/components/TakeATest/TakeATestCertificate";
import ViewCustomHiringQuestion from "../shared/components/ViewCustomHiringQuestion";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {
	const routes: RouterProps[] = [
		{ exact: false, path: appRoutes.AUTH, component: AuthWrapper },
		{ exact: true, path: appRoutes.TOPICS, component: isAuthenticated(Topics) },
		{ exact: true, path: appRoutes.PROPOSE_TOPIC, component: isAuthenticated(ProposeTopic) },
		{ exact: true, path: appRoutes.VIEW_TOPIC, component: isAuthenticated(Topic) },
		{ exact: true, path: appRoutes.CONTRIBUTE_TOPIC_QUESTION, component: isAuthenticated(NewQuestionView) },
		{ exact: true, path: appRoutes.VIEW_PROPOSED_TOPIC, component: isAuthenticated(ProposedTopic) },
		// CHANGED
		// { exact: true, path: appRoutes.TAKE_A_TOPIC_TEST, component: isAuthenticated(TakeATest) },
		{ exact: true, path: appRoutes.TAKE_A_TOPIC_TEST, component: isAuthenticated(TakeATestCertificate) },

		{ exact: true, path: appRoutes.TAKE_HIRINGTEST, component: isAuthenticated(TakeHiringTest) },
		// CHANGED
		// { exact: true, path: appRoutes.TAKE_A_TEST, component: isAuthenticated(TakeATest) },
		{ exact: true, path: appRoutes.TAKE_A_TEST, component: isAuthenticated(TakeATestCertificate) },
		{ exact: true, path: appRoutes.TAKE_A_TEST_CERTIFICATE, component: isAuthenticated(TakeATestCertificate) },
		{ exact: true, path: appRoutes.START_A_TEST_VIEW, component: isAuthenticated(StartTest) },

		{ exact: true, path: appRoutes.TAKE_A_TEST_VIEW, component: isAuthenticated(TestView) },

		{ exact: true, path: appRoutes.DEPLOY_HIRINGTEST, component: isAuthenticated(DeployHiringTest) },
		{ exact: true, path: appRoutes.DEPLOY_CERTIFICATE_HIRINGTEST, component: isAuthenticated(DeployHiringTestForm) },
		{ exact: true, path: appRoutes.DEPLOY_CUSTOM_HIRINGTEST, component: isAuthenticated(DeployHiringTestForm) },

		{ exact: true, path: appRoutes.MANAGE_HIRINGTESTS, component: isAuthenticated(ManageHiringTest) },
		{ exact: true, path: appRoutes.VIEW_HIRINGTEST, component: isAuthenticated(ViewHiringTest) },
		{ exact: true, path: appRoutes.ADD_HIRINGTEST_QUESTION, component: isAuthenticated(NewQuestionView) },
		{ exact: true, path: appRoutes.VIEW_HIRINGTEST_REPORT, component: isAuthenticated(HiringTestReport) },
		{ exact: true, path: appRoutes.VIEW_HIRINGTEST_QUESTION, component: isAuthenticated(ViewCustomHiringQuestion) },
		{ exact: true, path: appRoutes.VIEW_HIRINGTEST_REPORT_QUESTION, component: isAuthenticated(Question) },

		{ exact: true, path: appRoutes.EARN_CERTIFICATE, component: isAuthenticated(EarnCerificate) },
		{ exact: true, path: appRoutes.VIEW_CERTIFICATE, component: isAuthenticated(Certificate) },
		{ exact: true, path: appRoutes.VIEW_CERTIFICATE_META, component: Certificate },

		{ exact: true, path: appRoutes.CONTRIBUTE_QUESTIONS, component: isAuthenticated(ContributeQuestions) },
		{ exact: true, path: appRoutes.VIEW_QUESTION, component: isAuthenticated(Question) },

		{ exact: true, path: appRoutes.PROFILE, component: isAuthenticated(Profile) },
	];

	return (
		<div>
			<Router history={appHistory}>
				<Navbar />
				<div>
					<Switch>
						{routes.map((route, index) => (
							<Route
								key={index}
								{...route}
								component={route.component as FC}
							/>
						))}
						<Route
							path="/"
							render={() => <Redirect to={appRoutes.TOPICS} />}
						/>
						<Route
							path="*"
							render={() => <Redirect to={appRoutes.LOGIN} />}
						/>
					</Switch>
				</div>
			</Router>
		</div>
	);
};

export default AppRoutes;
