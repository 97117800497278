import React, { FC } from 'react'
import { Typography } from 'antd';

const { Paragraph } = Typography;

interface ParagraphEllipsisProps {
    className?: string;
    text: string;
    symbol?: string;
    expandable?: boolean;
    onClick?: ()=>void;
}

const ParagraphEllipsis: FC<ParagraphEllipsisProps> = (props) => {
    const {className, text, symbol, expandable, onClick} = props
    return (
        <Paragraph onClick={onClick} className={className} ellipsis={{rows: 1, expandable: expandable, symbol: symbol}}>{text}</Paragraph>
    )
}

export default ParagraphEllipsis
