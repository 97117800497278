import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { User, ProdUser, LinkedInAuthModel } from "../../models/Auth/auth.model";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { HOME, LOGIN, RESET, TOPICS, VERIFY, TERMS_AND_CONDITIONS } from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import LocalStorage from "../../shared/localStorage";

const AuthService = () => {
	const history = useHistory();

	const [user, setUser] = useState<ProdUser>();

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

	const login = (data: ProdUser) => {
		setLoading(true);
		const loginJson = {
			"user": {
				...data
			}
		}
		return axiosInstance
			.post(ApiRoutes.LOGIN, loginJson)
			.then((response) => {
				const userDetails = deserialize(ProdUser, response.data["user"]);
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: userDetails,
					},
				});
				LocalStorage.setItem("tiger-user-access-token", response.data["token"]);
				LocalStorage.setItem("tiger-user-token", userDetails);
				LocalStorage.setItem("new-tiger-user", response.data["first_login"]);
				Notification({
					message: "Login",
					description: "Logged in successfully",
					type: NotificationTypes.SUCCESS,
				});
				setUser(userDetails);
				history.push(TERMS_AND_CONDITIONS)
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const signUp = (data: User) => {
		setLoading(true);
		const { email } = data
		delete data.password2
		delete Object.assign(data, { ["password"]: data["password1"] })["password1"];
		return axiosInstance
			.post(ApiRoutes.SIGN_UP, {
				"user": data
			})
			.then((response) => {
				if (response.data.success) {
					history.push(VERIFY, { email, module: "sign_up" })
				}
				// store.dispatch({
				// 	type: AUTHENTICATED,
				// 	payload: {
				// 		authenticated: true,
				// 		user: userDetails,
				// 	},
				// });
				// // LocalStorage.setItem("tiger-access-token", response.headers);
				// // LocalStorage.setItem("tiger-token", userDetails);
				// Notification({
				// 	message: "Login",
				// 	description: "Logged in successfully",
				// 	type: NotificationTypes.SUCCESS,
				// });
				// setUser(userDetails);
				// history.push(TOPICS);
			})
			.catch((error) => {
				Notification({
					message: "Sign up failed",
					description: "Email has already been taken",
					type: NotificationTypes.ERROR,
				});
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const signUpVerify = (data: User) => {
		setLoading(true);
		const forgotJson = {
			"user": {
				...serialize(User, data)
			}
		}
		return axiosInstance
			.put(ApiRoutes.SIGN_UP_VERIFY, forgotJson)
			.then((response) => {
				const userDetails = deserialize(ProdUser, response.data["user"]);
				// store.dispatch({
				// 	type: AUTHENTICATED,
				// 	payload: {
				// 		authenticated: true,
				// 		user: userDetails,
				// 	},
				// });
				// LocalStorage.setItem("tiger-user-access-token", response.headers);
				// LocalStorage.setItem("tiger-user-token", userDetails);
				Notification({
					message: "Login",
					description: "Logged in successfully",
					type: NotificationTypes.SUCCESS,
				});
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: userDetails,
					},
				});
				LocalStorage.setItem("tiger-user-access-token", response.data["token"]);
				LocalStorage.setItem("tiger-user-token", userDetails);
				LocalStorage.setItem("new-tiger-user", response.data["first_login"]);

				setUser(userDetails);
				history.push(TERMS_AND_CONDITIONS)
			})
			.catch((error) => {
				Notification({
					message: "Invalid OTP",
					description: "re-check your otp and try again",
					type: NotificationTypes.ERROR,
				});
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const forgotPassword = (email: User) => {
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.FORGOT_PASSWORD, {
				...email
			})
			.then((response) => {
				const status = response.data.success
				if (status) {
					history.push(VERIFY, { ...email, module: "reset" });
				}
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const forgotPasswordOTP = (data: User) => {
		setLoading(true);
		// const forgotJson = {
		// 	"user":{
		// 		...serialize(User, data)
		// 	}
		// }
		return axiosInstance
			.post(ApiRoutes.VERIFY_OTP, { ...data })
			.then((response) => {
				const status = response.data.success
				if (status) {
					history.push(RESET, { email: data?.email });
				}
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const resetPassword = (data: User) => {
		setLoading(true);
		delete data.password2
		delete Object.assign(data, { ["password"]: data["password1"] })["password1"];
		// const resetJson = {
		// 	"user":{
		// 		...serialize(User, data)
		// 	}
		// }
		return axiosInstance
			.post(ApiRoutes.RESET_PASSWORD, { ...data })
			.then((response) => {
				const status = response.data.success
				Notification({
					message: "Password reset",
					description: "Login with your new password",
					type: NotificationTypes.SUCCESS,
				});
				if (status) {
					history.push(LOGIN);
				}
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const logOut = () => {
		setLoading(true);
		return axiosInstance
			.delete(ApiRoutes.LOGOUT)
			.then((response) => {
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const loginLinkedIn = (data: LinkedInAuthModel) => {
		setLoading(true);
		const payload = serialize(LinkedInAuthModel, data)
		return axiosInstance
			.post(ApiRoutes.LINKED_IN_LOGIN, payload)
			.then((response) => {
				const userDetails = deserialize(User, response.data["user"]);
				const token = response.data["token"];
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: userDetails,
					},
				});
				LocalStorage.setItem("tiger-user-access-token", response.data["token"]);
				LocalStorage.setItem("tiger-user-token", userDetails);
				LocalStorage.setItem("new-tiger-user", response.data["first_login"]);
				Notification({
					message: "Login",
					description: "Logged in successfully",
					type: NotificationTypes.SUCCESS,
				});
				setUser(userDetails);
				history.push(TERMS_AND_CONDITIONS)
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const signupLinkedIn = (data: LinkedInAuthModel) => {
		setLoading(true);
		const payload = serialize(LinkedInAuthModel, data)
		return axiosInstance
			.post(ApiRoutes.LINKED_IN_REGISTER, payload)
			.then((response) => {
				const userDetails = deserialize(User, response.data["user"]);
				const token = response.data["token"];
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: userDetails,
					},
				});
				LocalStorage.setItem("tiger-user-access-token", response.data["token"]);
				LocalStorage.setItem("tiger-user-token", userDetails);
				LocalStorage.setItem("new-tiger-user", response.data?.["first_login"]);
				Notification({
					message: "Login",
					description: "Logged in successfully",
					type: NotificationTypes.SUCCESS,
				});
				setUser(userDetails);
				history.push(TERMS_AND_CONDITIONS)
			})
			.catch((error) => {
				// Notification({
				// 	message: "Sign up failed",
				// 	description: "Incorrect email or password",
				// 	type: NotificationTypes.ERROR,
				// });
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return {
		user,
		error,
		loading,
		login,
		signUp,
		signUpVerify,
		forgotPassword,
		forgotPasswordOTP,
		resetPassword,
		loginLinkedIn,
		signupLinkedIn,
		logOut
	};
};

export default AuthService;
