import { Button } from 'antd'
import { Form, Formik } from 'formik'
import React from 'react'
import UserService from '../../../services/AuthService/auth.service'
import InputField from '../../../shared/components/InputField'
import { resetSchema } from '../Validations'

const ResetForm = (props:any) => {
    const email=props.location.state.email
    const { user, error, loading, resetPassword } = UserService();
    const initialValue = {
        password1: "",
        password2: ""
    }
    const onSubmit=(data:any)=>{
        resetPassword({...data,email})
    }

    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={resetSchema}
            >
                <Form className="reset__form">
                    <h2 className="form-header reset">Reset Password</h2>
                    <InputField type="password" name="password1" placeholder="New Password" />
                    <InputField type="password" name="password2" placeholder="Confirm Password" />
                    <Button type="primary" htmlType="submit">Reset Password</Button>
                </Form>
            </Formik>
        </div>
    )
}

export default ResetForm
